import React, { Component } from "react";
export const MyContext = React.createContext();

export class MyProvider extends Component {
  state = {
    language: "fr"
  };

  changeLanguage = language => {
    this.setState({
      language
    });
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          changeLanguage: this.changeLanguage
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}
