import React, { useState } from "react";
import TeamCardPrimary from "./TeamCardPrimary";
import TeamCardDetails from "./TeamCardDetails";
import { CustomCard } from '../../atoms';

const TeamCard = ({
  title,
  subtitle,
  image,
  languages,
  diplomas,
}) => {
  const [showPrimaryInfo, setShowPrimaryInfo] = useState(true);
  const toggleInfo = () => setShowPrimaryInfo(!showPrimaryInfo);

  return (
    <CustomCard onClick={toggleInfo}>
      {showPrimaryInfo ? (
        <TeamCardPrimary
          title={title}
          subtitle={subtitle}
          image={image}
          languages={languages}
        />
      ) : (
        <TeamCardDetails
          title={title}
          subtitle={subtitle}
          image={image}
          languages={languages}
          diplomas={diplomas}
        />
      )}
    </CustomCard>
  );
}

export default TeamCard;
