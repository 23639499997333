import React from "react";
import PropTypes from "prop-types";
import { Grid, AppBar, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { compose, withStateHandlers } from "recompose";
import NavbarLogo from "../../assets/Logo/NavbarLogo";
import MobileLogo from "../../assets/Logo/Mobile";
import { Button } from "../../atoms";
import { makeStyles } from "@material-ui/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/icons/Menu";
import { getContent } from "../../utils";

const useStyles = makeStyles({
  list: {
    backgroundColor: "#224e9d!important"
  }
});

const InvisibleButton = styled(ScrollLink)`
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  line-height: 3.5em !important;
  color: #224e9d!important
  text-decoration: none !important;
`;

const MenuItem = styled(ScrollLink)`
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  line-height: 3.5em !important;
  text-transform: uppercase !important;
  color: white!important;
  text-decoration: none !important;
`;

const SwipeableTemporaryDrawer = ({ language }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false
  });

  const toggleDrawer = open => () => {
    setState({ ...state, top: open });
  };

  return (
    <div>
      <span onClick={toggleDrawer(true)}>
        <Menu style={{ color: "#224e9d", fontSize: 45 }} />
      </span>

      <div>
        <SwipeableDrawer
          open={state.top}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          anchor="top"
        >
          <div
            tabIndex={1}
            role="button"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className={classes.list}
          >
            <List>
              <ListItem button onClick={toggleDrawer(false)} style={{ width: '100%' }}>
                <ListItemIcon>
                  <MenuItem variant="h5">
                    <Link
                      to="/"
                      onClick={toggleDrawer(false)}
                      style={{ textDecoration: "none", color: "white" }}
                      reloadDocument
                    >
                      Home
                    </Link>
                  </MenuItem>
                </ListItemIcon>
                <ListItemText />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemIcon>
                  <MenuItem variant="h5">
                    <Link
                      to="/renouvellement"
                      onClick={toggleDrawer(false)}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Renouvellement d'Ordonnance
                    </Link>
                  </MenuItem>
                </ListItemIcon>
                <ListItemText />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemIcon>
                  <MenuItem variant="h5">
                    <Link
                      to="/schedule"
                      onClick={toggleDrawer(false)}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {getContent("nav", "scheduleAppointment", language)}
                    </Link>
                  </MenuItem>
                </ListItemIcon>
                <ListItemText />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    </div >
  );
};

const styles = {
  root: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

const Nav = styled(Grid)`
  height: 100px;
  z-index: 9999 !important;
  background-color: white !important;
  padding-left: 45px;
  padding-right: 45px;
`;

const CustomLink = styled(ScrollLink)`
  margin: 0 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 12px;
  color: #224e9d;
  text-decoration: none;
  padding: 9px 4px
  cursor: pointer;
  border-bottom:${({ selected }) =>
    selected ? " 3px solid #d4494e" : "none"}}};
  `;

  const ButtonLink = styled(Link)`
  margin: 0 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 12px;
  color: #224e9d;
  text-decoration: none;
  padding: 9px 4px
  cursor: pointer;
  border-bottom:${({ selected }) =>
    selected ? " 3px solid #d4494e" : "none"}}};
  `;



const CustomRedirectionLink = styled(Link)`
  margin: 0 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 12px;
  color: #224e9d;
  text-decoration: none;
  padding: 9px 4px
  cursor: pointer;
  border-bottom:${({ selected }) =>
    selected ? " 3px solid #d4494e" : "none"}}};
  `;

// const languages = {
//   pt: <Portuguese />,
//   en: <English />,
//   fr: <French />,
//   de: <German />
// };

// const languagesText = {
//   pt: "Português",
//   en: "English",
//   fr: "Français",
//   de: "Deutsch"
// };

const Navbar = props => {
  const {
    classes,
    selected,
    setSelected,
    openModal,
  } = props;
  const { language } = props.context.state;
  const url = window.location.href.split('/');
  console.log('window.location.href.', window.location.href)
  const current = url[url.length - 1];
  const isHome = current !== 'schedule' && current !== 'renouvellement';
  return (
    <AppBar position="fixed" className={classes.root}>
      <Nav
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className="topnav"
      >
        <Grid item>
          <Link to="/">
            <div className="hideable">
              <NavbarLogo />
            </div>
            <div className="hidden" style={{ paddingLeft: "10px" }}>
              <MobileLogo />
            </div>
          </Link>
        </Grid>
        <Grid item className="hideable">
          <Toolbar>
            {
              isHome && (
                <>
                  <CustomLink
                    to="services"
                    selected={"services" === selected}
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    onSetActive={to => setSelected(to)}
                  >
                    {getContent("nav", "services", language)}
                  </CustomLink>
                  <CustomLink
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    onSetActive={to => setSelected(to)}
                    selected={"team" === selected}
                  >
                    {getContent("nav", "team", language)}
                  </CustomLink>
                  <CustomLink
                    to="emergencies"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    onSetActive={to => setSelected(to)}
                    selected={"emergencies" === selected}
                  >
                    {getContent("nav", "emergencies", language)}
                  </CustomLink>
                  <CustomLink
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    offset={-250}
                    duration={500}
                    onSetActive={to => setSelected(to)}
                    selected={"contact-us" === selected}
                  >
                    {getContent("nav", "contactUs", language)}
                  </CustomLink>
                </>
              )
            }
            {
              !isHome && (
                <InvisibleButton onClick={() => setSelected("")}>
                  <div style={{ marginRight: "12px" }}>
                    <ButtonLink to="/">
                      Home
                    </ButtonLink>
                  </div>
                </InvisibleButton>
              )
            }
            <InvisibleButton onClick={() => setSelected("")}>
              <ButtonLink to="/renouvellement">
                Renouvellement d'Ordonnance
              </ButtonLink>
            </InvisibleButton>
            <Button onClick={() => setSelected("")}>
              <ButtonLink to="/schedule">
                {getContent("nav", "scheduleAppointment", language)}
              </ButtonLink>
            </Button>
          </Toolbar>
        </Grid>
        <div className="hidden" style={{ paddingRight: "10px" }}>
          <SwipeableTemporaryDrawer
            language={language}
            openModal={openModal}
          />
        </div>
      </Nav>
    </AppBar>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  withStateHandlers(
    {
      selected: "",
      modalOpen: false,
      selectedLanguage: "en"
    },
    {
      setSelected: () => selected => ({ selected }),
      handleChange: ({ step }) => ({ field, value }) => ({
        [field]: value,
        step: step + 1
      }),
      openModal: () => () => ({ modalOpen: true }),
      closeModal: () => value => ({
        modalOpen: false,
        selectedLanguage: value
      })
    }
  )
)(Navbar);
