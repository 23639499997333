import React from "react";
import Home from "../Home";
import Services from "../Services";
import Team from "../Team";
import Emergencies from "../Emergencies";
import ContactUs from "../ContactUs";
import Footer from "../Footer";

const MainLayout = ({
  context,
}) => {
  return (
    <>
      <Home />
      <Services />
      <Team />
      <Emergencies />
      <ContactUs />
      <Footer context={context} />
    </>
  );
};

export default MainLayout;
