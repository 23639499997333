import React from "react";

const Monochromatic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="232"
    height="95.906"
    viewBox="0 0 232 95.906"
  >
    <g
      id="Symbol_40_1"
      data-name="Symbol 40 – 1"
      transform="translate(-80 -4734)"
    >
      <path
        id="Path_934"
        data-name="Path 934"
        d="M800.482,316.007l-9.152,1.477,0,26.651h9.155Z"
        transform="translate(-711.303 4454.673)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_935"
        data-name="Path 935"
        d="M791.316,356.115l-.022,8.786a18.876,18.876,0,0,0,.522,4.639,7.423,7.423,0,0,0,1.966,3.5,9.6,9.6,0,0,0,3.931,2.241,22.965,22.965,0,0,0,6.483.862l1.288-7.56a8.123,8.123,0,0,1-2.641-.552,3.709,3.709,0,0,1-1.505-1.105,3.825,3.825,0,0,1-.708-1.659,12.255,12.255,0,0,1-.184-2.212l.025-6.944Z"
        transform="translate(-711.294 4442.692)"
        fill="#224e9d"
        opacity="0.3"
      />
      <path
        id="Path_936"
        data-name="Path 936"
        d="M979.914,337.533a51.949,51.949,0,0,0-6.02,1.259V370h9.154V345.057l-.073-7.89C981.911,337.262,980.936,337.37,979.914,337.533Z"
        transform="translate(-765.837 4448.352)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_937"
        data-name="Path 937"
        d="M1041.488,360.02a9.208,9.208,0,0,1-2.643.367q-6.207,0-6.2-7.678a9.918,9.918,0,0,1,1.782-6.082,6.3,6.3,0,0,1,5.4-2.4c.82,0,1.536.032,2.152.089s1.142.135,1.591.215v-7.481c-.133-.012-.262-.034-.393-.045q-1.751-.153-3.41-.151a19.941,19.941,0,0,0-6.942,1.133,14.47,14.47,0,0,0-5.19,3.226,13.857,13.857,0,0,0-3.227,5.009,18.189,18.189,0,0,0-1.107,6.481q0,7.125,3.564,11.09t10.259,3.962a16.15,16.15,0,0,0,3.41-.337,14.811,14.811,0,0,0,3.042-1.013l0-7.25A10,10,0,0,1,1041.488,360.02Z"
        transform="translate(-780.595 4448.444)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_938"
        data-name="Path 938"
        d="M1061.358,366.458c0-.173.006-.348.006-.523V338.78q-1.231-.37-2.8-.708c-1.044-.22-2.14-.419-3.285-.582q-1.529-.214-3.08-.363v7.488l0,14.624,0,7.25,9.137-.032Z"
        transform="translate(-789.228 4448.364)"
        fill="#224e9d"
        opacity="0.8"
      />
      <path
        id="Path_939"
        data-name="Path 939"
        d="M1044.255,380.148a6.179,6.179,0,0,1-1.628,4.424q-1.629,1.718-5.56,1.719a25.4,25.4,0,0,1-9.89-1.842l-1.6,7.68a37.011,37.011,0,0,0,5.471,1.382,33.622,33.622,0,0,0,5.9.523q8.416,0,12.442-3.841a10.867,10.867,0,0,0,.927-1c.1-.123.184-.262.278-.391.181-.243.365-.48.527-.741.1-.158.184-.334.277-.5.139-.25.283-.492.408-.755.087-.184.159-.384.24-.576.11-.264.225-.525.323-.8.071-.205.128-.426.192-.637.086-.286.176-.569.249-.868.055-.226.1-.464.144-.7.063-.306.128-.611.176-.931.038-.247.063-.506.093-.76.039-.326.079-.651.105-.989.02-.27.029-.551.041-.829.011-.255.034-.5.038-.762h-.013l-9.137.032Z"
        transform="translate(-781.275 4435.871)"
        fill="#224e9d"
        opacity="0.3"
      />
      <path
        id="Path_940"
        data-name="Path 940"
        d="M1075.717,365.066c-.009-.051-.016-.1-.025-.156a20.1,20.1,0,0,0,.853,4.016,14.072,14.072,0,0,0,3.133,5.314,14.869,14.869,0,0,0,5.344,3.532,20.387,20.387,0,0,0,7.588,1.288,32.147,32.147,0,0,0,3.44-.182q1.719-.185,3.225-.46a26.443,26.443,0,0,0,2.736-.645,12.419,12.419,0,0,0,1.966-.74l-1.228-7.432a18.833,18.833,0,0,1-3.965,1.137,26.517,26.517,0,0,1-4.944.459,10.623,10.623,0,0,1-6.268-1.659,6.081,6.081,0,0,1-2.64-4.485v.013Z"
        transform="translate(-796.245 4440.065)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_941"
        data-name="Path 941"
        d="M1109.666,356.189h-20.8v6.256h20.765c.041-.493.084-1.052.122-1.686s.063-1.215.063-1.752C1109.814,358.022,1109.756,357.092,1109.666,356.189Z"
        transform="translate(-800.179 4442.67)"
        fill="#224e9d"
        opacity="0.8"
      />
      <path
        id="Path_942"
        data-name="Path 942"
        d="M1084.861,350.324v0a12.978,12.978,0,0,1,.584-2.3,6.121,6.121,0,0,1,1.138-2,5.438,5.438,0,0,1,1.842-1.385,6.156,6.156,0,0,1,2.643-.52,5.839,5.839,0,0,1,2.671.551,5.621,5.621,0,0,1,1.782,1.415,5.711,5.711,0,0,1,1.043,1.964,9.382,9.382,0,0,1,.4,2.275h8.7c-.394-4.064-1.668-7.26-3.844-9.553q-4-4.21-10.874-4.209a15.454,15.454,0,0,0-5.745,1.107,14.424,14.424,0,0,0-4.944,3.253,16.037,16.037,0,0,0-3.469,5.376c-.176.426-.321.879-.462,1.336-.107.364-.206.736-.293,1.116-.015.062-.03.127-.044.191-.06.276-.117.559-.168.844h-.023a23.305,23.305,0,0,0-.173,6.661c.008.052.016.105.025.156h9.213v-6.273Z"
        transform="translate(-796.176 4448.534)"
        fill="#224e9d"
        opacity="0.3"
      />
      <path
        id="Path_943"
        data-name="Path 943"
        d="M834.34,368.981c-.818.041-1.678.06-2.577.06a7.993,7.993,0,0,1-3.964-.828,3.147,3.147,0,0,1-1.444-2.982,3.235,3.235,0,0,1,.493-1.842,3.417,3.417,0,0,1,1.35-1.168,6.358,6.358,0,0,1,2-.583,18.089,18.089,0,0,1,4.453-.03c.733.083,1.283.163,1.651.245l.01-5.96h0a14.343,14.343,0,0,0-2.241-.4,29.334,29.334,0,0,0-3.166-.154,24.668,24.668,0,0,0-5.1.522,13.8,13.8,0,0,0-4.362,1.692,8.728,8.728,0,0,0-3.041,3.133,9.421,9.421,0,0,0-1.137,4.794,11.335,11.335,0,0,0,.982,4.974,8.269,8.269,0,0,0,2.827,3.287,12.292,12.292,0,0,0,4.39,1.812,26.747,26.747,0,0,0,5.685.555c1.874,0,3.572-.069,5.132-.193l.011-7.115A18.686,18.686,0,0,1,834.34,368.981Z"
        transform="translate(-719.051 4442.924)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_944"
        data-name="Path 944"
        d="M844.41,356.129l-.01,5.96h.007v6.943l-.017,0-.011,7.115c.48-.039.956-.077,1.405-.126l.814-.108c.379-.051.769-.1,1.126-.158q3.5-.583,5.47-1.012V356.129Z"
        transform="translate(-727.151 4442.688)"
        fill="#224e9d"
        opacity="0.8"
      />
      <path
        id="Path_945"
        data-name="Path 945"
        d="M846.372,350.333a30.664,30.664,0,0,0-.175-3.161c-.015-.123-.033-.244-.05-.365-.039-.276-.081-.547-.129-.814-.029-.161-.06-.317-.09-.471-.075-.358-.153-.714-.248-1.056a9.728,9.728,0,0,0-2.3-4.178,10.483,10.483,0,0,0-4.332-2.639,21.089,21.089,0,0,0-6.758-.924q-1.6,0-3.164.155t-2.98.368q-1.412.214-2.61.49c-.8.182-1.465.359-2,.522l1.166,7.373a19.722,19.722,0,0,1,3.932-.952,31.184,31.184,0,0,1,4.607-.339q3.5,0,4.915,1.414a5.067,5.067,0,0,1,1.412,3.747l.017.83h8.785"
        transform="translate(-720.328 4448.484)"
        fill="#224e9d"
        opacity="0.3"
      />
      <g
        id="Group_222"
        data-name="Group 222"
        transform="translate(80.33 4734)"
        opacity="0.5"
      >
        <g id="Group_221" data-name="Group 221" transform="translate(0 0.691)">
          <path
            id="Path_946"
            data-name="Path 946"
            d="M803.33,289.936a12.059,12.059,0,0,1-1.933.165,4.257,4.257,0,0,1-3.551-1.3,5.837,5.837,0,0,1-1.016-3.666,5.621,5.621,0,0,1,1.084-3.6,3.929,3.929,0,0,1,3.283-1.368,8,8,0,0,1,1.867.2,12.458,12.458,0,0,1,1.533.466l1.032-3.967a12.541,12.541,0,0,0-2.266-.716,12.283,12.283,0,0,0-2.565-.25,9.36,9.36,0,0,0-3.8.734,8.373,8.373,0,0,0-4.615,4.917,10.3,10.3,0,0,0-.618,3.582,12.354,12.354,0,0,0,.518,3.649,7.6,7.6,0,0,0,1.633,2.917,7.761,7.761,0,0,0,2.85,1.95,10.786,10.786,0,0,0,4.131.716,14.279,14.279,0,0,0,2.868-.267,11.475,11.475,0,0,0,2.166-.633l-.7-4.065A8.237,8.237,0,0,1,803.33,289.936Z"
            transform="translate(-791.764 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_947"
            data-name="Path 947"
            d="M822.738,275.9a8.414,8.414,0,0,0-3.116.6,7.845,7.845,0,0,0-2.684,1.767,8.784,8.784,0,0,0-1.884,2.918,10.743,10.743,0,0,0-.716,4.081,11.392,11.392,0,0,0,.551,3.6,7.592,7.592,0,0,0,1.7,2.884,8.037,8.037,0,0,0,2.9,1.917,11.086,11.086,0,0,0,4.117.7,17.285,17.285,0,0,0,1.865-.1q.935-.1,1.752-.249a14.79,14.79,0,0,0,1.483-.349,6.991,6.991,0,0,0,1.067-.4l-.668-4.032a10.153,10.153,0,0,1-2.149.616,14.33,14.33,0,0,1-2.684.249,5.766,5.766,0,0,1-3.4-.9,3.309,3.309,0,0,1-1.433-2.434h11.267c.021-.266.044-.571.066-.917s.032-.659.032-.948q0-4.433-2.166-6.716A7.744,7.744,0,0,0,822.738,275.9Zm-3.3,7.465a7.179,7.179,0,0,1,.318-1.25,3.357,3.357,0,0,1,.617-1.083,2.92,2.92,0,0,1,1-.749A3.322,3.322,0,0,1,822.8,280a3.156,3.156,0,0,1,1.451.3,3.016,3.016,0,0,1,.966.767,3.094,3.094,0,0,1,.567,1.066,5.25,5.25,0,0,1,.218,1.232Z"
            transform="translate(-798.507 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_948"
            data-name="Path 948"
            d="M856.814,278.233a5.582,5.582,0,0,0-2.35-1.65,10.259,10.259,0,0,0-3.716-.582,26.862,26.862,0,0,0-4.2.316,27.879,27.879,0,0,0-3.266.682v16.932h4.964V280.4c.313-.046.666-.083,1.068-.118s.777-.049,1.133-.049a2.581,2.581,0,0,1,2.367,1,6.7,6.7,0,0,1,.634,3.4v9.3h4.964v-9.9a13.455,13.455,0,0,0-.367-3.268A6.446,6.446,0,0,0,856.814,278.233Z"
            transform="translate(-807.153 -268.069)"
            fill="#224e9d"
          />
          <path
            id="Path_949"
            data-name="Path 949"
            d="M880.417,287.952a9.455,9.455,0,0,1-1.649.149,2.358,2.358,0,0,1-2.217-.933,4.907,4.907,0,0,1-.548-2.5V278.5h5.964v-4.131H876v-5.166l-4.967.8v14.731a13.991,13.991,0,0,0,.318,3.1,5.7,5.7,0,0,0,1.115,2.383,5.275,5.275,0,0,0,2.134,1.532,8.881,8.881,0,0,0,3.366.551,11.724,11.724,0,0,0,2.7-.267,14.14,14.14,0,0,0,2-.633l-.7-3.867A8.531,8.531,0,0,1,880.417,287.952Z"
            transform="translate(-815.443 -266.039)"
            fill="#224e9d"
          />
          <path
            id="Path_950"
            data-name="Path 950"
            d="M901.673,276.231q-.568-.082-1.133-.134c-.378-.033-.7-.05-.968-.05a18.234,18.234,0,0,0-4.049.415,30.431,30.431,0,0,0-3.15.885v16.6h4.966v-13.4a6.727,6.727,0,0,1,.984-.183,8.985,8.985,0,0,1,1.116-.083,9.852,9.852,0,0,1,1.933.182c.6.124,1.122.24,1.567.352l.834-4.1a5.4,5.4,0,0,0-.968-.284Q902.24,276.314,901.673,276.231Z"
            transform="translate(-821.817 -268.082)"
            fill="#224e9d"
          />
          <path
            id="Path_951"
            data-name="Path 951"
            d="M919.166,275.9a8.417,8.417,0,0,0-3.116.6,7.855,7.855,0,0,0-2.683,1.767,8.737,8.737,0,0,0-1.882,2.918,10.687,10.687,0,0,0-.717,4.081,11.373,11.373,0,0,0,.551,3.6,7.6,7.6,0,0,0,1.7,2.884,8.026,8.026,0,0,0,2.9,1.917,11.09,11.09,0,0,0,4.117.7,17.362,17.362,0,0,0,1.866-.1q.933-.1,1.75-.249a14.878,14.878,0,0,0,1.483-.349,6.9,6.9,0,0,0,1.066-.4l-.667-4.032a10.151,10.151,0,0,1-2.149.616,14.321,14.321,0,0,1-2.682.249,5.764,5.764,0,0,1-3.4-.9,3.3,3.3,0,0,1-1.433-2.434h11.266c.021-.266.043-.571.065-.917s.034-.659.034-.948q0-4.433-2.166-6.716A7.745,7.745,0,0,0,919.166,275.9Zm-3.3,7.465a7.12,7.12,0,0,1,.316-1.25,3.346,3.346,0,0,1,.618-1.083,2.921,2.921,0,0,1,1-.749,3.309,3.309,0,0,1,1.433-.283,3.15,3.15,0,0,1,1.45.3,3.015,3.015,0,0,1,.968.767,3.127,3.127,0,0,1,.567,1.066,5.275,5.275,0,0,1,.215,1.232Z"
            transform="translate(-827.311 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_952"
            data-name="Path 952"
            d="M973.945,278.233a5.185,5.185,0,0,0-2.15-1.65,8.776,8.776,0,0,0-3.449-.582,8.631,8.631,0,0,0-2.55.432,8.536,8.536,0,0,0-2.449,1.2,6.986,6.986,0,0,0-1.95-1.15,8.293,8.293,0,0,0-3.084-.482q-.967,0-1.966.082c-.666.056-1.318.134-1.95.234s-1.234.211-1.8.332-1.061.239-1.484.35v16.932h4.967V280.4c.312-.046.634-.083.966-.118a9.606,9.606,0,0,1,.967-.049,2.285,2.285,0,0,1,2.117,1,6.842,6.842,0,0,1,.616,3.4v9.3h4.966v-9.566q0-.965-.067-1.816a7.616,7.616,0,0,0-.3-1.616,4.238,4.238,0,0,1,1.185-.5,4.419,4.419,0,0,1,1.116-.2,2.286,2.286,0,0,1,2.117,1,6.851,6.851,0,0,1,.616,3.4v9.3h4.966v-9.9a16.448,16.448,0,0,0-.3-3.268A6.262,6.262,0,0,0,973.945,278.233Z"
            transform="translate(-839.362 -268.069)"
            fill="#224e9d"
          />
          <path
            id="Path_953"
            data-name="Path 953"
            d="M999.293,275.9a8.424,8.424,0,0,0-3.117.6,7.85,7.85,0,0,0-2.682,1.767,8.753,8.753,0,0,0-1.884,2.918,10.729,10.729,0,0,0-.715,4.081,11.413,11.413,0,0,0,.549,3.6,7.591,7.591,0,0,0,1.7,2.884,8.047,8.047,0,0,0,2.9,1.917,11.077,11.077,0,0,0,4.117.7,17.373,17.373,0,0,0,1.867-.1q.933-.1,1.75-.249a14.993,14.993,0,0,0,1.483-.349,6.969,6.969,0,0,0,1.065-.4l-.666-4.032a10.164,10.164,0,0,1-2.151.616,14.307,14.307,0,0,1-2.681.249,5.765,5.765,0,0,1-3.4-.9,3.3,3.3,0,0,1-1.432-2.434h11.264c.022-.266.045-.571.067-.917s.034-.659.034-.948q0-4.433-2.167-6.716A7.742,7.742,0,0,0,999.293,275.9Zm-3.3,7.465a7.1,7.1,0,0,1,.314-1.25,3.4,3.4,0,0,1,.618-1.083,2.965,2.965,0,0,1,1-.749,3.316,3.316,0,0,1,1.433-.283,3.146,3.146,0,0,1,1.45.3,3.007,3.007,0,0,1,.967.767,3.106,3.106,0,0,1,.567,1.066,5.249,5.249,0,0,1,.217,1.232Z"
            transform="translate(-851.245 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_954"
            data-name="Path 954"
            d="M1029.98,273.422a10,10,0,0,0-1.652-.567,7.776,7.776,0,0,0-1.848-.2,8.217,8.217,0,0,0-3.316.633,6.544,6.544,0,0,0-2.45,1.834,8.391,8.391,0,0,0-1.534,2.916,12.944,12.944,0,0,0-.532,3.849,10.938,10.938,0,0,0,.616,3.783,7.906,7.906,0,0,0,1.782,2.866,7.785,7.785,0,0,0,2.866,1.816,11.077,11.077,0,0,0,3.867.633c.6,0,1.228-.027,1.883-.082s1.3-.13,1.933-.218,1.238-.194,1.816-.316,1.089-.25,1.533-.385V264.69l-4.965.8Zm0,13.165c-.245.046-.557.083-.932.116s-.792.049-1.235.049a3.563,3.563,0,0,1-3.065-1.4,6.071,6.071,0,0,1-1.034-3.7q0-4.8,3.533-4.8a5.8,5.8,0,0,1,1.516.2,4.243,4.243,0,0,1,1.218.5Z"
            transform="translate(-859.535 -264.69)"
            fill="#224e9d"
          />
          <rect
            id="Rectangle_1493"
            data-name="Rectangle 1493"
            width="4.965"
            height="17.53"
            transform="translate(180.077 8.333)"
            fill="#224e9d"
          />
          <path
            id="Path_955"
            data-name="Path 955"
            d="M1050.794,265.167a3.037,3.037,0,0,0-2.081.783,3.054,3.054,0,0,0,0,4.3,3.161,3.161,0,0,0,4.164,0,3.052,3.052,0,0,0,0-4.3A3.04,3.04,0,0,0,1050.794,265.167Z"
            transform="translate(-868.251 -264.832)"
            fill="#224e9d"
          />
          <path
            id="Path_956"
            data-name="Path 956"
            d="M1072.649,289.936a12.033,12.033,0,0,1-1.933.165,4.248,4.248,0,0,1-3.546-1.3,5.828,5.828,0,0,1-1.017-3.666,5.642,5.642,0,0,1,1.081-3.6,3.93,3.93,0,0,1,3.285-1.368,7.981,7.981,0,0,1,1.865.2,12.223,12.223,0,0,1,1.532.466l1.033-3.967a12.532,12.532,0,0,0-2.265-.716,12.274,12.274,0,0,0-2.566-.25,9.357,9.357,0,0,0-3.8.734,8.495,8.495,0,0,0-2.834,1.983,8.6,8.6,0,0,0-1.782,2.934,10.3,10.3,0,0,0-.619,3.582,12.329,12.329,0,0,0,.518,3.649,7.6,7.6,0,0,0,1.632,2.917,7.768,7.768,0,0,0,2.85,1.95,10.786,10.786,0,0,0,4.131.716,14.255,14.255,0,0,0,2.868-.267,11.468,11.468,0,0,0,2.168-.633l-.7-4.065A8.179,8.179,0,0,1,1072.649,289.936Z"
            transform="translate(-872.211 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_957"
            data-name="Path 957"
            d="M1096.668,277.837a5.737,5.737,0,0,0-2.351-1.433,11.519,11.519,0,0,0-3.666-.5q-.866,0-1.717.084t-1.614.2c-.513.076-.982.168-1.419.266s-.793.195-1.082.284l.633,4a11.008,11.008,0,0,1,2.134-.517,17.092,17.092,0,0,1,2.5-.182,3.715,3.715,0,0,1,2.666.767,2.747,2.747,0,0,1,.767,2.034v.467a7.712,7.712,0,0,0-1.217-.216,15.718,15.718,0,0,0-1.715-.084,13.324,13.324,0,0,0-2.769.283,7.438,7.438,0,0,0-2.365.918,4.731,4.731,0,0,0-1.648,1.7,5.112,5.112,0,0,0-.618,2.6,6.139,6.139,0,0,0,.535,2.7,4.493,4.493,0,0,0,1.531,1.784,6.713,6.713,0,0,0,2.382.984,14.61,14.61,0,0,0,3.083.3,28.34,28.34,0,0,0,4.6-.316q1.9-.318,2.967-.549V283.07a10.994,10.994,0,0,0-.368-2.966A5.3,5.3,0,0,0,1096.668,277.837Zm-3.151,12.467a9.545,9.545,0,0,1-1.067.1c-.445.021-.911.034-1.4.034a4.325,4.325,0,0,1-2.15-.45,1.7,1.7,0,0,1-.783-1.618,1.766,1.766,0,0,1,.266-1,1.853,1.853,0,0,1,.736-.633,3.427,3.427,0,0,1,1.081-.316,10.018,10.018,0,0,1,1.318-.083,10.829,10.829,0,0,1,1.1.066c.4.045.7.09.9.134Z"
            transform="translate(-878.813 -268.04)"
            fill="#224e9d"
          />
          <path
            id="Path_958"
            data-name="Path 958"
            d="M1117.4,286.487a2.039,2.039,0,0,1-.816-.6,2.068,2.068,0,0,1-.384-.9,6.642,6.642,0,0,1-.1-1.2v-19.1l-4.965.8v19.3a10.272,10.272,0,0,0,.283,2.517,4.045,4.045,0,0,0,1.066,1.9,5.235,5.235,0,0,0,2.134,1.215,12.4,12.4,0,0,0,3.518.467l.7-4.1A4.475,4.475,0,0,1,1117.4,286.487Z"
            transform="translate(-887.16 -264.69)"
            fill="#224e9d"
          />
        </g>
        <path
          id="Path_959"
          data-name="Path 959"
          d="M1005.315,266.483l-5.445,4.6-2.267-2.019,4.682-5.359Z"
          transform="translate(-853.249 -263.704)"
          fill="#224e9d"
        />
      </g>
      <path
        id="Path_960"
        data-name="Path 960"
        d="M878.044,316.007l-9.152,1.477,0,26.651h9.155Z"
        transform="translate(-734.471 4454.673)"
        fill="#224e9d"
        opacity="0.8"
      />
      <path
        id="Path_961"
        data-name="Path 961"
        d="M868.878,356.115l-.022,8.786a18.8,18.8,0,0,0,.524,4.639,7.42,7.42,0,0,0,1.965,3.5,9.6,9.6,0,0,0,3.931,2.241,22.958,22.958,0,0,0,6.483.862l1.288-7.56a8.131,8.131,0,0,1-2.642-.552,3.712,3.712,0,0,1-1.5-1.105,3.827,3.827,0,0,1-.708-1.659,12.2,12.2,0,0,1-.184-2.212l.025-6.942Z"
        transform="translate(-734.462 4442.692)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_962"
        data-name="Path 962"
        d="M905.678,316.217l-9.152,1.477-.005,26.515h9.157Z"
        transform="translate(-742.726 4454.61)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_963"
        data-name="Path 963"
        d="M896.512,356.131l-.022,8.921a18.836,18.836,0,0,0,.523,4.639,7.415,7.415,0,0,0,1.966,3.5,9.568,9.568,0,0,0,3.931,2.241,22.957,22.957,0,0,0,6.483.862l1.288-7.56a8.127,8.127,0,0,1-2.642-.552,3.7,3.7,0,0,1-1.5-1.105,3.811,3.811,0,0,1-.708-1.659,12.132,12.132,0,0,1-.184-2.212l.025-7.078Z"
        transform="translate(-742.716 4442.688)"
        fill="#224e9d"
        opacity="0.3"
      />
      <path
        id="Path_964"
        data-name="Path 964"
        d="M939.391,368.938q-1.228.062-2.578.062a7.974,7.974,0,0,1-3.963-.829,3.143,3.143,0,0,1-1.445-2.98,3.246,3.246,0,0,1,.492-1.842,3.41,3.41,0,0,1,1.351-1.168,6.323,6.323,0,0,1,2-.583,18,18,0,0,1,4.453-.03q1.1.124,1.652.244l.009-5.958h0a14.126,14.126,0,0,0-2.242-.4,29.1,29.1,0,0,0-3.166-.156,24.577,24.577,0,0,0-5.1.523,13.828,13.828,0,0,0-4.361,1.69,8.738,8.738,0,0,0-3.041,3.134,9.412,9.412,0,0,0-1.138,4.793,11.345,11.345,0,0,0,.982,4.974,8.253,8.253,0,0,0,2.827,3.287,12.277,12.277,0,0,0,4.391,1.812,26.74,26.74,0,0,0,5.684.555c1.874,0,3.572-.068,5.132-.193l.01-7.113A18.5,18.5,0,0,1,939.391,368.938Z"
        transform="translate(-750.43 4442.937)"
        fill="#224e9d"
        opacity="0.3"
      />
      <path
        id="Path_965"
        data-name="Path 965"
        d="M949.46,356.088l-.009,5.958.007,0v6.941l-.018,0-.01,7.113c.479-.037.957-.076,1.405-.126.272-.036.553-.072.814-.108.379-.05.769-.1,1.126-.156q3.5-.584,5.47-1.012l-.011-18.587Z"
        transform="translate(-758.529 4442.7)"
        fill="#224e9d"
        opacity="0.5"
      />
      <path
        id="Path_966"
        data-name="Path 966"
        d="M951.411,350.322l0-.428a23.553,23.553,0,0,0-.16-2.764c-.016-.123-.034-.243-.051-.365-.039-.276-.081-.547-.13-.814-.028-.158-.059-.315-.09-.471-.074-.358-.154-.713-.247-1.055a9.727,9.727,0,0,0-2.3-4.178,10.5,10.5,0,0,0-4.332-2.64,21.134,21.134,0,0,0-6.758-.922c-1.065,0-2.12.053-3.164.154s-2.039.224-2.981.37-1.812.306-2.61.49-1.465.359-2,.522l1.166,7.372a19.834,19.834,0,0,1,3.932-.952,31.4,31.4,0,0,1,4.608-.339q3.5,0,4.915,1.414a5.07,5.07,0,0,1,1.412,3.747l.017.832Z"
        transform="translate(-751.707 4448.497)"
        fill="#224e9d"
        opacity="0.8"
      />
      <path
        id="Path_967"
        data-name="Path 967"
        d="M1004.968,345.643a11.9,11.9,0,0,0-2.273-4.67,10.342,10.342,0,0,0-4.333-3.042,18.9,18.9,0,0,0-6.849-1.072s-.877.008-1.874.034c-.059,0-.119,0-.178,0a23.068,23.068,0,0,0-2.657.177v0l.027,0c-.047.011.021.01,0,.022l.07,7.868c.575-.079,1.229-.152,1.969-.212s1.43-.092,2.086-.092q3.195,0,4.362,1.841t1.169,6.268v17.14h9.153V351.665A24.854,24.854,0,0,0,1004.968,345.643Z"
        transform="translate(-769.694 4448.444)"
        fill="#224e9d"
        opacity="0.3"
      />
    </g>
  </svg>
);

export default Monochromatic;
