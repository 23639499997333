import React from "react";

const Prevention = () => (
  <svg
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.163 18.162"
    enableBackground="new 0 0 18.163 18.162"
  >
    <g>
      <g>
        <path
          fill="#DF6161"
          d="M9.081,0C4.073,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.304,7.708c0.058,0.035,0.117,0.068,0.176,0.104
			c0.178,0.104,0.358,0.206,0.544,0.299c0.101,0.051,0.203,0.098,0.306,0.145c0.178,0.08,0.357,0.155,0.54,0.225
			c0.106,0.041,0.214,0.081,0.323,0.117c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.292,0.078
			c0.302,0.067,0.608,0.124,0.921,0.161c0.354,0.042,0.711,0.069,1.076,0.069c1.276,0,2.49-0.268,3.594-0.744
			c0.185-0.08,0.363-0.17,0.541-0.262c0.214-0.109,0.41-0.246,0.612-0.371c0.521-0.322,1.017-0.677,1.461-1.094
			c1.765-1.658,2.874-4.004,2.874-6.61C18.163,4.073,14.089,0,9.081,0z M15.235,14.158c-0.553,0.67-1.219,1.24-1.958,1.7
			c-0.175,0.108-0.351,0.212-0.534,0.307c-0.25,0.13-0.51,0.242-0.776,0.343c-0.233,0.087-0.474,0.16-0.716,0.229
			c-0.055,0.015-0.105,0.036-0.158,0.05h0.061l0,0h-0.061c-0.125,0.032-0.244,0.079-0.37,0.105
			c-0.171,0.036-0.347,0.059-0.522,0.083c-0.366,0.053-0.738,0.088-1.119,0.088c-0.298,0-0.591-0.019-0.881-0.051
			c-0.396-0.044-0.779-0.124-1.156-0.224c-0.012-0.003-0.022-0.008-0.034-0.011l0,0c-0.237-0.064-0.47-0.141-0.698-0.226
			c-0.041-0.015-0.08-0.032-0.121-0.048c-0.191-0.075-0.38-0.156-0.564-0.245c-0.037-0.019-0.074-0.037-0.11-0.056
			c-0.192-0.098-0.382-0.201-0.565-0.313c-0.021-0.012-0.041-0.023-0.061-0.036c-0.205-0.128-0.405-0.263-0.598-0.408v0.001
			C2.356,13.991,1.1,11.685,1.1,9.081C1.1,4.68,4.68,1.1,9.081,1.1s7.982,3.58,7.982,7.981
			C17.063,11.008,16.376,12.778,15.235,14.158z"
        />
      </g>
      <circle fill="#4360AC" cx="9.129" cy="7.392" r="1.316" />
      <path
        fill="#DF6161"
        d="M13.821,11.488c-0.022-0.002-0.043-0.014-0.066-0.014h-0.619c-0.034,0.062-0.065,0.126-0.101,0.187
		c-0.709,1.217-1.604,2.237-2.64,2.721v0.422c0,0.027,0.014,0.051,0.016,0.077c0.041,0.482,0.433,0.864,0.926,0.864
		c0.521,0,0.942-0.421,0.942-0.941v-1.445h1.476c0.521,0,0.942-0.421,0.942-0.941C14.697,11.92,14.31,11.524,13.821,11.488z"
      />
      <path
        fill="#DF6161"
        d="M11.337,9.024c-0.521,0-0.941,0.422-0.941,0.943v1.507h-0.008v0.187v1.6v0.082
		c0.001,0,0.001-0.002,0.002-0.002c0.584-0.383,1.122-1.012,1.599-1.746c0.101-0.155,0.196-0.32,0.291-0.485V9.968
		C12.279,9.446,11.857,9.024,11.337,9.024z"
      />
      <path
        fill="#4360AC"
        d="M14.497,5.536L9.312,3.259C9.253,3.231,9.19,3.22,9.126,3.22c-0.017,0-0.032,0-0.021,0.002L9.061,3.22
		c-0.063,0-0.126,0.012-0.185,0.037L3.668,5.536C3.476,5.62,3.384,5.8,3.427,5.97c0.013,2.629,1.747,7.166,4.329,8.396
		c0.426,0.203,0.87,0.326,1.338,0.326c0.45,0,0.882-0.113,1.294-0.305c0.001,0,0.001-0.002,0.002-0.002
		c0.002-0.001,0.004-0.003,0.006-0.004c1.035-0.483,1.931-1.504,2.64-2.721c0.035-0.061,0.066-0.125,0.101-0.187
		c0.998-1.791,1.595-3.957,1.6-5.474C14.782,5.8,14.69,5.62,14.497,5.536z M12.279,11.109c-0.095,0.165-0.19,0.33-0.291,0.485
		c-0.477,0.734-1.015,1.363-1.599,1.746c-0.001,0-0.001,0.002-0.002,0.002v-0.082v-1.6v-0.187v-0.823
		c0-0.732-0.589-1.326-1.316-1.326c-0.727,0-1.315,0.594-1.315,1.326v2.668c-1.832-1.23-3.287-4.695-3.396-7.086l4.734-2.072
		l4.714,2.072C13.739,7.715,13.148,9.594,12.279,11.109z"
      />
      <path
        fill="#563D60"
        d="M13.035,11.661c0.035-0.061,0.066-0.125,0.101-0.187h-0.856v-0.001v-0.364
		c-0.095,0.165-0.19,0.33-0.291,0.485c-0.477,0.734-1.015,1.363-1.599,1.746c-0.001,0-0.001,0.002-0.002,0.002v-0.082v-1.6v-0.187
		H8.92c-0.521,0-0.942,0.422-0.942,0.942s0.422,0.941,0.942,0.941h1.473h0.003v1.023C11.431,13.898,12.326,12.878,13.035,11.661z"
      />
    </g>
  </svg>
);

export default Prevention;
