import * as translationEN from "../translations/en";
import * as translationPT from "../translations/pt";
import * as translationDE from "../translations/de";
import * as translationFR from "../translations/fr";

export const getContent = (domain, str, language = "fr˙") => {
  switch (language) {
    case "en":
      return translationEN.default[domain][str];
    case "pt":
      return translationPT.default[domain][str];
    case "de":
      return translationDE.default[domain][str];
    case "fr":
      return translationFR.default[domain][str];
    default:
      return translationFR.default[domain][str];
  }
};

