import React from "react";
import { Typography, Grid } from "@material-ui/core";
import SectionTitle from "../SectionTitle";
import { MyContext as Language } from "../../context";
import {
  Address,
  BigText,
  ConsultationContext,
  ClinicName,
  City,
  EmergencyWrapper,
  ImportantTitle,
  ImportantContent,
  Information,
  SubTitle,
  SubTitleSmall,
} from '../../atoms';
import { getContent } from "../../utils";

const Emergencies = () => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <EmergencyWrapper>
          <div className="emergencies">
            <SectionTitle text={getContent("emergencies", "emergencies", language)} />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <SubTitle variant="h5">
                  {getContent("emergencies", "inCaseOfEmergency", language)}
                </SubTitle>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item lg={8}>
                <SubTitleSmall variant="h5">
                  {getContent("emergencies", "description", language)}
                </SubTitleSmall>
              </Grid>
            </Grid>

            <Typography />
            <Grid
              container
              spacing={40}
              direction="row"
              justify="space-evenly"
              alignItems="stretch"
              style={{ marginTop: 30 }}
            >
              <Grid item md={4} sm={10} xs={10}>
                <ConsultationContext className="emergency-card">
                  <ClinicName variant="h6">
                    Maison Médicale du Centre
                  </ClinicName>
                  {language === "fr" ? (
                    <City variant="subtitle1"> Luxembourg-Ville </City>
                  ) : (
                    <City variant="subtitle1"> Luxembourg city</City>
                  )}
                  <Address variant="subtitle1">
                    23, Val Fleuri
                    <br />
                    L-1526 Luxembourg
                  </Address>
                </ConsultationContext>
              </Grid>
              <Grid item md={4} sm={10} xs={10}>
                <ConsultationContext className="emergency-card">
                  <ClinicName variant="h6"> Maison Médicale du Sud </ClinicName>
                  <City variant="subtitle1"> Esch-sur-Alzette </City>
                  <Address variant="subtitle1">
                    3-5, avenue du Swing
                    <br />
                    L-4367 Belvaux
                  </Address>
                </ConsultationContext>
              </Grid>
              <Grid item md={4} sm={10} xs={10}>
                <ConsultationContext className="emergency-card">
                  <ClinicName variant="h6">
                    Maison Médicale du Nord
                  </ClinicName>
                  <City variant="subtitle1"> Ettelbruck </City>
                  <Address variant="subtitle1">
                    110, avenue Lucien Salentiny L-9080 Ettelbruck
                  </Address>
                </ConsultationContext>
              </Grid>
              <Grid item md={4} className="opening-hours">
                <BigText variant="h3">
                  {getContent("emergencies", "opening", language)}
                  <br className="hideable" />
                  <span className="hide"> </span>
                  {getContent("emergencies", "hours", language)}
                </BigText>
              </Grid>
              <Grid item md={4} sm={8} className="opening-hours">
                <Information variant="subtitle1" style={{ fontWeight: 600 }}>
                  {getContent("emergencies", "onBusinessDays", language)}
                </Information>
                <Information variant="subtitle1">
                  {getContent("emergencies", "businessDaysHours", language)}
                </Information>
                <Information variant="subtitle1" style={{ fontWeight: 600 }}>
                  {getContent("emergencies", "weekends", language)}
                </Information>
                <Information variant="subtitle1">
                  {getContent("emergencies", "weekendsHours", language)}
                </Information>
              </Grid>
              <Grid item md={4} sm={6} className="opening-hours">
                <Information variant="subtitle1">
                  {getContent("emergencies", "untillMidnight", language)}
                </Information>
                <Information variant="subtitle1">
                  {getContent("emergencies", "afterMidnight", language)}
                </Information>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="stretch"
            >
              <Grid item sm={8} className="more-info">
                <ImportantTitle variant="h6">
                  {getContent("emergencies", "moreInfo", language)}
                </ImportantTitle>
                <ImportantContent>
                  <a href="http://sante.public.lu/fr/urgences-gardes/services-garde/maisons-medicales/index.html">
                    Maisons Médicales de garde
                  </a>
                </ImportantContent>
              </Grid>
            </Grid>
          </div>
          <SectionTitle text={getContent("emergencies", "access", language)} />
        </EmergencyWrapper>
      );
    }}
  </Language.Consumer>
);

export default Emergencies;
