import React from "react";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Monochromatic from "../../assets/Logo/Monochromatic";
import { Description, ServicesContainer, Title } from '../../atoms';

import { MyContext as Language } from "../../context";
import { getContent } from "../../utils";


const MapWithAMarker = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lng: 5.973298564417598, lat: 49.5084068993996 }}
      defaultOptions={{
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#444444"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 45
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#d5e6ec"
              },
              {
                visibility: "on"
              }
            ]
          }
        ]
      }}
    >
      <Marker position={{ lng: 5.973298564417598, lat: 49.5084068993996 }} />
    </GoogleMap>
  ))
);

const ContactUs = () => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <ServicesContainer className="contact-us">
          <a href="https://maps.app.goo.gl/P6t62yDCpR4gDhrc7">
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj8Uv_9WEBLQYk35aoUU4ypMFB5gIwMtc&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `550px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              style={{ marginTop: "50px!important" }}
            />
          </a>
          <Grid container>
            <Grid item lg={4} md={6} className="public-transportation">
              <Paper style={{ padding: "30px", boxShadow: "none" }}>
                <Title variant="h6">
                  {getContent("access", "publicTransportation", language)}
                </Title>
                <Description variant="body1">
                  {"- "}
                  Arrêts "RUE GUILLAUME CAPUS" et "RUE MARCELLE DAUPHIN" - Ligne 7
                </Description>
                <Description variant="body1">
                  {"- "}
                  BusArrêt "SCHLUECHTHAUS" - Lignes 607, 608
                </Description>
                <Description variant="body1">
                  {"- "}
                  BusArrêt "CITÉ VERTE" Lignes 13, 17, 600, 601, 607, 608
                </Description>
                <Description variant="body1">
                  {"- "}
                  BusArrêt "OP ZAEPERT" - Lignes 13, 17, 600, 601, 610, 611, 612, 621, 640, 641, 650, 660, 662, 670, 785, 900
                </Description>
                <Description variant="body1">
                  {getContent("access", "details", language)}{" "}
                  <a href="https://travelplanner.mobiliteit.lu/hafas/query.exe/fn">
                    Mobilitéitszentral
                  </a>
                  .
                </Description>
              </Paper>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "50px" }} className="access">
            <Grid item lg={4} className="logo-container">
              <Monochromatic />
            </Grid>
          </Grid>
          <Grid
            container
            className="contacts"
            justify="center"
            alignItems="center"
          >
            <Grid item lg={4} sm={10} xs={10}>
              <Title variant="h6">{getContent("access", "address", language)}</Title>
              <Description variant="body1">
                42 Rue Guillaume Capus <br />
                L-4071 Esch-sur-Alzette
              </Description>
            </Grid>
            <Grid item lg={4} sm={10} xs={10}>
              <Title variant="h6">{getContent("access", "contacts", language)}</Title>
              <Description variant="body1">
                Tel .: (+352) 26 17 50 68 <br />
                Fax : (+352) 26 55 24 11 <br />
              </Description>
            </Grid>
            <Grid item lg={4} sm={10} xs={10}>
              <Title variant="h6">
                {getContent("access", "secretariatOpeningHours", language)}
              </Title>
              <Description variant="body1">
                {getContent("access", "mondayToFriday", language)}
                <br />
                {getContent("access", "officeScheduleStart", language)} <br />
                {getContent("access", "officeScheduleEnd", language)} <br />
                Vendredi: <br /> de 8h00 à 12h00
              </Description>
            </Grid>
          </Grid>
        </ServicesContainer>
      );
    }}
  </Language.Consumer>
);

export default ContactUs;
