import React from "react";

const ChronicDisease = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.164 18.162"
    enableBackground="new 0 0 18.164 18.162"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          fill="#DF6161"
          d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.057,0.035,0.117,0.068,0.176,0.104
			c0.178,0.104,0.357,0.206,0.543,0.299c0.102,0.051,0.203,0.098,0.307,0.145c0.178,0.08,0.357,0.155,0.539,0.225
			c0.107,0.041,0.215,0.081,0.324,0.117c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.291,0.078
			c0.303,0.067,0.609,0.124,0.922,0.161c0.354,0.042,0.711,0.069,1.076,0.069c1.275,0,2.49-0.268,3.594-0.744
			c0.184-0.08,0.363-0.17,0.541-0.262c0.213-0.109,0.41-0.246,0.611-0.371c0.521-0.322,1.018-0.677,1.461-1.094
			c1.766-1.658,2.875-4.004,2.875-6.61C18.164,4.073,14.09,0,9.082,0z M15.236,14.158c-0.553,0.67-1.219,1.24-1.959,1.7
			c-0.174,0.108-0.35,0.212-0.533,0.307c-0.25,0.13-0.51,0.242-0.777,0.343c-0.232,0.087-0.473,0.16-0.715,0.229
			c-0.055,0.015-0.105,0.036-0.158,0.05h0.061l0,0h-0.061c-0.125,0.032-0.244,0.079-0.371,0.105
			c-0.17,0.036-0.346,0.059-0.521,0.083c-0.367,0.053-0.738,0.088-1.119,0.088c-0.299,0-0.592-0.019-0.881-0.051
			c-0.396-0.044-0.779-0.124-1.156-0.224c-0.012-0.003-0.023-0.008-0.035-0.011l0,0c-0.236-0.064-0.469-0.141-0.697-0.226
			c-0.041-0.015-0.08-0.032-0.121-0.048C6,16.429,5.811,16.348,5.627,16.259c-0.037-0.019-0.074-0.037-0.111-0.056
			c-0.191-0.098-0.381-0.201-0.564-0.313c-0.021-0.012-0.041-0.023-0.061-0.036c-0.205-0.128-0.406-0.263-0.598-0.408v0.001
			C2.357,13.991,1.1,11.685,1.1,9.081C1.1,4.68,4.68,1.1,9.082,1.1c4.4,0,7.982,3.58,7.982,7.981
			C17.064,11.008,16.377,12.778,15.236,14.158z"
        />
      </g>
      <g>
        <g>
          <path
            fill="#4360AC"
            d="M6.656,6.025l0.812,0.82c0.178,0.178,0.266,0.35,0.266,0.515c0,0.136-0.088,0.308-0.266,0.516
				C7.43,7.92,7.161,8.193,6.656,8.696C6.527,8.825,6.38,8.891,6.215,8.891c-0.167,0-0.314-0.065-0.441-0.194
				c-0.13-0.128-0.194-0.276-0.194-0.44c0-0.16,0.064-0.306,0.194-0.435L6.232,7.36L5.773,6.9c-0.13-0.128-0.194-0.273-0.194-0.434
				c0-0.164,0.064-0.312,0.194-0.441C5.9,5.897,6.048,5.832,6.215,5.832C6.38,5.832,6.527,5.897,6.656,6.025z"
          />
        </g>
        <g>
          <path
            fill="#4360AC"
            d="M9.326,6.025l0.812,0.82c0.178,0.178,0.267,0.35,0.267,0.515c0,0.136-0.089,0.308-0.267,0.516
				C10.102,7.92,9.83,8.193,9.326,8.696c-0.128,0.129-0.275,0.194-0.44,0.194c-0.167,0-0.312-0.065-0.443-0.194
				C8.313,8.568,8.251,8.42,8.251,8.256c0-0.16,0.062-0.306,0.191-0.435L8.903,7.36L8.442,6.9C8.313,6.772,8.251,6.627,8.251,6.467
				c0-0.164,0.062-0.312,0.191-0.441c0.131-0.128,0.276-0.193,0.443-0.193C9.051,5.832,9.198,5.897,9.326,6.025z"
          />
        </g>
      </g>
      <path
        fill="none"
        d="M11.052,9.6V8.738v-0.76V5.186H4.876V9.6h1.027c0.276,0,0.504,0.229,0.512,0.517l0.038,1.324l1.624-1.685
		C8.173,9.655,8.304,9.6,8.439,9.6h0.7h0.732H11.052z"
      />
      <path
        fill="#DF6161"
        d="M11.728,11.178c0,0.188,0.152,0.342,0.342,0.342s0.342-0.153,0.342-0.342v-0.525h0.537
		c0.188,0,0.342-0.153,0.342-0.342c0-0.189-0.153-0.343-0.342-0.343h-0.537V9.419c0-0.185-0.148-0.336-0.334-0.34v1.053
		c0,0.273-0.202,0.49-0.458,0.521h0.108V11.178z"
      />
      <path
        fill="#4360AC"
        d="M14.65,7.979h-2.573V4.652c0-0.293-0.229-0.531-0.513-0.531H4.363c-0.284,0-0.514,0.238-0.514,0.531v1.282
		L3.79,5.874C3.66,5.744,3.514,5.68,3.348,5.68c-0.167,0-0.312,0.064-0.443,0.194C2.776,6.001,2.713,6.15,2.713,6.315
		c0,0.16,0.063,0.304,0.191,0.434l0.461,0.46l-0.461,0.46C2.776,7.798,2.713,7.942,2.713,8.103c0,0.164,0.063,0.313,0.191,0.441
		c0.131,0.13,0.276,0.194,0.443,0.194c0.166,0,0.312-0.064,0.442-0.194c0.023-0.023,0.037-0.037,0.06-0.06v1.647
		c0,0.295,0.229,0.532,0.514,0.532h1.042l0.057,2.039c0.006,0.212,0.134,0.401,0.324,0.479c0.062,0.025,0.126,0.038,0.188,0.038
		c0.134,0,0.266-0.055,0.363-0.156l2.313-2.4H9.14v1.608c0,0.21,0.163,0.38,0.366,0.38h2.08l1.654,1.714
		c0.069,0.072,0.163,0.112,0.258,0.112c0.046,0,0.092-0.009,0.136-0.026c0.136-0.057,0.227-0.191,0.231-0.342l0.04-1.458h0.745
		c0.201,0,0.365-0.17,0.365-0.38V8.358C15.016,8.149,14.852,7.979,14.65,7.979z M9.14,9.6h-0.7c-0.136,0-0.267,0.056-0.362,0.156
		L6.453,11.44l-0.038-1.324C6.407,9.828,6.18,9.6,5.903,9.6H4.876V5.186h6.176v2.793v0.76V9.6h-1.18H9.14z M14.283,11.892h-0.732
		c-0.199,0-0.361,0.163-0.368,0.37l-0.026,0.945l-1.159-1.204c-0.069-0.071-0.162-0.111-0.259-0.111H9.872v-1.228h1.692
		c0.018,0,0.031-0.007,0.048-0.01c0.002,0,0.004-0.002,0.007-0.002c0.256-0.03,0.458-0.247,0.458-0.521V9.079V9.03V8.738h2.206
		V11.892z"
      />
      <path
        fill="#563D60"
        d="M4.601,7.725c0.177-0.208,0.266-0.381,0.266-0.516c0-0.166-0.089-0.338-0.266-0.516L4.178,6.268L3.85,5.935
		v0.333v1.887v0.33c0.123-0.123,0.235-0.236,0.328-0.33C4.429,7.903,4.575,7.754,4.601,7.725z"
      />
      <path
        fill="#563D60"
        d="M12.077,10.132V9.079c-0.003,0-0.005-0.002-0.008-0.002c-0.189,0-0.342,0.154-0.342,0.342v0.549H11.19
		c-0.188,0-0.342,0.153-0.342,0.343c0,0.177,0.137,0.317,0.308,0.335c0.012,0.001,0.022,0.007,0.034,0.007h0.429
		C11.875,10.622,12.077,10.405,12.077,10.132z"
      />
    </g>
  </svg>
);

export default ChronicDisease;
