import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Button, ButtonText, Subtitle } from '../../atoms';

let medicineList = [{ medicine: '', dose: '' }];

const MedicineForm = () => {
  const [medicines, setMedicines] = useState([{ medicine: '', dose: '' }]);
  const addNewField = () => {
    setMedicines([...medicines, { medicine: '', dose: '' }]);
    medicineList.push({ medicine: '', dose: '' })
  }
  const handleMedicineChange = (index, type, value) => {
    medicineList[index][type] = value;
    setMedicines(medicineList);
  }

  return (
    <>
      <Subtitle>
        Médicaments
      </Subtitle>
      <Grid container style={{ width: '100%' }}>
        {medicines.map((_, i) => {
          return (
            <>
              <Grid item sm={8} style={{ marginLeft: '-30px', marginTop: '16px' }}>
                <TextField
                  variant="standard"
                  label="Medicament"
                  name={`medicine-${i + 1}`}
                  style={{ width: '90%' }}
                  onChange={(e) => handleMedicineChange(i, 'medicine', e.target.value)}
                />
              </Grid>
              <Grid item sm={2} style={{ marginTop: '16px' }}>
                <TextField
                  variant="standard"
                  label="Dosage"
                  name={`dose-${i + 1}`}
                  style={{ width: '90%' }}
                  onChange={(e) => handleMedicineChange(i, 'dose', e.target.value)}
                />
              </Grid>
            </>
          )
        })}
        <Grid container style={{ marginTop: '12px', marginLeft: '-30px' }}>
          <Button onClick={(e) => { e.preventDefault(); addNewField(); }}>
            <ButtonText>
              Ajouter Médicament
            </ButtonText>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};


export default MedicineForm;
