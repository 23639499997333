import React from "react";

const TravelMedicine = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.164 18.162"
    enableBackground="new 0 0 18.164 18.162"
  >
    <g>
      <path
        fill="#DF6161"
        d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.059,0.035,0.117,0.068,0.176,0.104
		c0.178,0.104,0.359,0.206,0.545,0.299c0.1,0.051,0.203,0.098,0.305,0.145c0.046,0.021,0.093,0.038,0.139,0.058
		c0.017,0.007,0.032,0.013,0.049,0.02c0.117,0.051,0.233,0.102,0.354,0.147c0.105,0.041,0.213,0.081,0.322,0.117
		c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.293,0.078c0.301,0.067,0.607,0.124,0.92,0.161
		c0.354,0.042,0.711,0.069,1.076,0.069c1.266,0,2.467-0.264,3.562-0.732c0.004-0.001,0.007-0.002,0.011-0.004
		c0.007-0.003,0.015-0.005,0.021-0.008c0.122-0.053,0.239-0.114,0.357-0.173c0.061-0.03,0.123-0.058,0.184-0.089
		c0.215-0.109,0.41-0.246,0.613-0.371c0.52-0.322,1.016-0.677,1.461-1.094c1.764-1.658,2.873-4.004,2.873-6.61
		C18.164,4.073,14.09,0,9.082,0z M15.236,14.158c-0.553,0.67-1.219,1.24-1.957,1.7c-0.081,0.05-0.164,0.094-0.246,0.141
		c-0.096,0.056-0.189,0.114-0.289,0.166c-0.029,0.015-0.061,0.025-0.09,0.04c-0.002,0.001-0.003,0.001-0.005,0.002
		c-0.002,0.001-0.004,0.002-0.006,0.003c-0.221,0.109-0.445,0.211-0.677,0.299c-0.232,0.087-0.473,0.16-0.714,0.228
		c-0.054,0.015-0.105,0.036-0.159,0.05c-0.125,0.032-0.244,0.079-0.369,0.105c-0.172,0.036-0.348,0.059-0.523,0.083
		c-0.365,0.053-0.738,0.088-1.119,0.088c-0.297,0-0.59-0.019-0.881-0.051c-0.395-0.044-0.779-0.124-1.156-0.224
		c-0.012-0.003-0.022-0.007-0.033-0.01v0c-0.239-0.064-0.473-0.141-0.701-0.226c-0.039-0.014-0.075-0.031-0.113-0.045
		c-0.194-0.076-0.385-0.158-0.571-0.248c-0.036-0.018-0.072-0.036-0.108-0.054c0,0,0,0-0.001,0
		c-0.016-0.009-0.031-0.019-0.048-0.027c-0.177-0.091-0.352-0.185-0.521-0.288c-0.019-0.011-0.038-0.022-0.056-0.034
		c-0.207-0.128-0.407-0.264-0.6-0.409v0.001C2.357,13.992,1.1,11.685,1.1,9.081C1.1,4.68,4.682,1.1,9.082,1.1
		c4.402,0,7.982,3.58,7.982,7.981C17.064,11.008,16.377,12.778,15.236,14.158z"
      />
      <path
        fill="#4360AC"
        d="M14.545,9.151l0.019-0.01l-2.011-0.629v-0.45c0-0.307-0.248-0.556-0.555-0.556s-0.555,0.249-0.555,0.555
		v0.104l-1.02-0.319V4.914c0-0.002-0.001-0.003-0.001-0.005V4.824h-0.017c-0.048-0.702-0.614-1.262-1.323-1.263
		c-0.71,0-1.275,0.56-1.324,1.262H7.741v0.086c0,0.002-0.001,0.003-0.001,0.005v2.932L6.723,8.165V8.061
		c-0.002-0.307-0.25-0.555-0.555-0.555c-0.307,0-0.556,0.249-0.556,0.554v0.453l-2.011,0.63l0.017,0.009
		c-0.544,0.173-0.945,0.662-0.945,1.26c0.001,0.542,0.328,1,0.794,1.212c0.016,0.007,0.029,0.021,0.046,0.027
		c0.158,0.064,0.33,0.103,0.512,0.103H4.24H5.5h0.13h2.11v1.769v0.031v0.595v0.154v0.901v0.134l-1.832,0.573l0.011,0.005
		c-0.164,0.052-0.304,0.154-0.402,0.288c0.001,0,0.001,0,0.001,0v0.873v0.337c0.117,0.051,0.233,0.102,0.354,0.147
		c0.105,0.041,0.213,0.081,0.322,0.117c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.293,0.078
		c0.301,0.067,0.607,0.124,0.92,0.161c0.354,0.042,0.711,0.069,1.076,0.069c1.266,0,2.467-0.264,3.562-0.732v-0.35v-0.871
		c0.002-0.001,0.004-0.002,0.006-0.003c-0.099-0.135-0.238-0.238-0.402-0.291l0.01-0.005l-1.833-0.574v-1.766v-0.148v-0.83v-0.84
		h0.652h1.536l0.053,0v0h1.475h0.001c0.746,0,1.351-0.601,1.351-1.343C15.491,9.812,15.09,9.323,14.545,9.151z M14.14,10.753
		l-1.465,0l-0.062,0h-1.536h-0.652H9.676H9.424v0.057v0.943v0.634v0.206v0.014v0.816v0.148v1.766v0.659
		c-0.102-0.058-0.212-0.101-0.337-0.101c-0.131,0-0.244,0.048-0.347,0.112v-0.669v-0.134v-1.681v-0.876v-0.259v-0.634v-0.992v-0.008
		H7.752H7.74H5.868H5.63H5.5H4.24H4.173H4.024c-0.193,0-0.352-0.154-0.352-0.342c0-0.168,0.134-0.271,0.248-0.307l1.301-0.421
		L5.868,9.48l0.043-0.014l0.185-0.058l0.517-0.162v0l0.409-0.128l0.661-0.207l0.065-0.021L8.039,8.8L8.74,8.58V7.846V5.125
		l0.017-0.232C8.77,4.705,8.909,4.562,9.083,4.562c0.173,0.001,0.312,0.143,0.325,0.331l0.015,0.172v0.01l0.001,0.001v2.771v0.735
		l0.701,0.22l1.02,0.319l0.408,0.127l0.416,0.13l-0.01,0.005l2.284,0.723c0.114,0.036,0.248,0.138,0.248,0.306
		C14.491,10.599,14.333,10.753,14.14,10.753z"
      />
      <g>
        <path
          fill="#563D60"
          d="M11.967,16.508c-0.232,0.087-0.473,0.16-0.714,0.228c-0.054,0.015-0.105,0.036-0.159,0.05
			c-0.125,0.032-0.244,0.079-0.369,0.105c-0.172,0.036-0.348,0.059-0.523,0.083c-0.365,0.053-0.738,0.088-1.119,0.088
			c-0.297,0-0.59-0.019-0.881-0.051c-0.395-0.044-0.779-0.124-1.156-0.224c-0.012-0.003-0.022-0.007-0.033-0.01v0
			c-0.239-0.064-0.473-0.141-0.701-0.226c-0.039-0.014-0.075-0.031-0.113-0.045c-0.194-0.076-0.385-0.158-0.571-0.248
			c-0.036-0.018-0.072-0.036-0.108-0.054v0.873v0.337c0.117,0.051,0.233,0.102,0.354,0.147c0.105,0.041,0.213,0.081,0.322,0.117
			c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.293,0.078c0.301,0.067,0.607,0.124,0.92,0.161
			c0.354,0.042,0.711,0.069,1.076,0.069c1.266,0,2.467-0.264,3.562-0.732v-0.35v-0.871C12.423,16.319,12.198,16.421,11.967,16.508z"
        />
      </g>
      <path
        fill="#DF6161"
        d="M9.228,10.762H8.74v0.992v0.634v0.259h0.487c0.069,0,0.131-0.025,0.196-0.04v-0.014v-0.206v-0.634V10.81
		c-0.044-0.01-0.084-0.031-0.13-0.034C9.271,10.773,9.251,10.762,9.228,10.762z"
      />
      <path
        fill="#DF6161"
        d="M7.752,10.753V9.254c0-0.122-0.027-0.236-0.069-0.343L7.021,9.119L6.612,9.247v0L6.096,9.409L5.911,9.467
		L5.868,9.48v1.273H7.74H7.752z"
      />
      <path
        fill="#DF6161"
        d="M7.74,13.522v-1.769H5.63H5.5H4.24H4.024c-0.182,0-0.354-0.038-0.512-0.103
		c-0.017-0.007-0.03-0.02-0.046-0.027c-0.003,0.028-0.017,0.052-0.017,0.08c0,0.521,0.422,0.942,0.942,0.942h1.473h0.003v1.444
		c0,0.028,0.014,0.051,0.016,0.078c0.041,0.482,0.433,0.864,0.926,0.864c0.5,0,0.899-0.392,0.931-0.884v-0.595V13.522z"
      />
      <path
        fill="#563D60"
        d="M6.096,9.409l0.517-0.162v0l0.409-0.128l0.661-0.207c-0.138-0.351-0.474-0.6-0.873-0.6
		c-0.521,0-0.941,0.421-0.941,0.942V9.48l0.043-0.014L6.096,9.409z"
      />
      <path
        fill="#563D60"
        d="M7.752,12.646H8.74v-0.259v-0.634v-0.992H7.752v0v-0.008H7.74H5.868v0.008H4.393
		c-0.492,0-0.884,0.381-0.926,0.862c0.016,0.007,0.029,0.021,0.046,0.027c0.158,0.064,0.33,0.103,0.512,0.103H4.24H5.5h0.13h2.11
		v1.769v0.031v0.595c0.001-0.02,0.012-0.038,0.012-0.058V12.646z"
      />
      <path
        fill="#563D60"
        d="M9.424,12.387v0.206v0.014c0.423-0.093,0.746-0.452,0.746-0.903c0-0.45-0.323-0.802-0.746-0.894v0.943
		V12.387z"
      />
    </g>
  </svg>
);

export default TravelMedicine;
