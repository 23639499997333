import React from "react";
import { Grid } from "@material-ui/core";
import TeamCard from "./TeamCard";
import SectionTitle from "../SectionTitle";
import SectionDescription from "../SectionDescription";
import david from "../../assets/Team/david2.jpg";
import paulina from "../../assets/Team/paulina3.png";
import camille from "../../assets/Team/camille2.jpg";
import { MyContext as Language } from "../../context";
import { getContent } from "../../utils";


const Team = () => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <div className="team">
          <SectionTitle text={getContent("team", "team", language)} />
          <Grid container justify="center">
            <Grid item>
              <SectionDescription text={getContent("team", "description", language)} />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item md={3} sm={10}>
              <TeamCard
                title="Dr David DA FLORÊNCIA RICARDO"
                subtitle={getContent("team", "davidDescription", language)}
                image={david}
                languages={getContent("team", "davidLanguages", language)}
                diplomas={[
                  getContent("team", "davidSpecialization", language),
                  getContent("team", "davidDoctorDegree", language),
                  getContent("team", "davidBachelorDegree", language)
                ]}
              />
            </Grid>
            <Grid item md={3} sm={10}>
              <TeamCard
                title="Ribeiro Freitas Ana Paulina"
                subtitle={getContent("team", "paulinaDescription", language)}
                image={paulina}
                languages={getContent("team", "paulinaLanguages", language)}
                diplomas={[
                  getContent("team", "paulinaMedicalSpecialization", language),
                  getContent("team", "paulinaEducation", language),
                  getContent("team", "paulinaHighschool", language)
                ]}
              />
            </Grid>
            <Grid item md={3} sm={10}>
              <TeamCard
                title="Dr Camille LEVEQUE"
                subtitle={getContent("team", "camilleDescription", language)}
                image={camille}
                languages={getContent("team", "camilleLanguages", language)}
                diplomas={[
                  getContent("team", "camilleMedicalSpecialization", language),
                  getContent("team", "camilleMedicalSpecialization2", language),
                  getContent("team", "camilleMedicalSpecialization3", language),
                  getContent("team", "camilleMedicalSpecialization4", language),
                  getContent("team", "camilleEducation", language),
                  getContent("team", "camilleHighschool", language)
                ]}
              />
            </Grid>
          </Grid>
        </div>
      );
    }}
  </Language.Consumer>
);

export default Team;
