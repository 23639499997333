import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { string } from "prop-types";

const Title = styled(Typography)`
  font-family: "Montserrat", sans-serif !important;
  color: #224e9d !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  padding-top: 50px;
  text-align: center;
  word-break: break-all!important;
  `;


const SectionTitle = ({ text }) => (
  <Grid container justify="center" alignItems="center">
    <Grid item md={10}>
      <Title variant="h3"> {text} </Title>
    </Grid>
  </Grid>
);

SectionTitle.propTypes = {
  text: string.isRequired
};

export default SectionTitle;
