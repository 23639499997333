import React from "react";
import { Grid } from "@material-ui/core";
import {
  Button,
  ButtonText,
  ContentText,
  Title,
  SchedulingWrapper,
} from '../../atoms';


const PickUpType = ({ setStep }) => {
  return (
    <SchedulingWrapper style={{ overflowX: "hidden" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={8} sm={12} style={{ backgroundColor: 'white', width: '100%', textAlign: 'center', padding: '50px', color: '#6f7b91 !important' }}>
          <Title variant="h6">
            Renouvellement d'Ordonnance
          </Title>
          <ContentText style={{ marginTop: '50px' }}>
            Formulaire de contact pour demande d'ordonnances de renouvellement d'un traitement chronique habituel
          </ContentText>
          <Grid container style={{ marginTop: '50px', width: '100%', marginLeft: '-15px' }}>
            <Grid item md={6} sm={12} style={{ width: '100%', marginTop: '12px' }}>
              <Button style={{ width: '95%', marginLeft: '5%' }} onClick={() => setStep(3)}>
                <ButtonText>
                  Envoi à domicile
                </ButtonText>
              </Button>
            </Grid>
            <Grid item md={6} sm={12} style={{ width: '100%', marginTop: '12px' }}>
              <Button style={{ width: '95%', marginLeft: '5%' }} onClick={() => setStep(4)}>
                <ButtonText>
                  À récupérer au cabinet
                </ButtonText>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SchedulingWrapper>
  );
};




export default PickUpType;