import React from "react";
import styled from "styled-components";
import { withStateHandlers, compose } from "recompose";
import { Card, Grid, Typography } from "@material-ui/core";
import { string, node } from "prop-types";

const CustomCard = styled(Card)`
  height: 292px;
  border-radius: 24px !important;
  margin-top: 20px;
  width: 90%:
  cursor: pointer !important;
  overflowY: scroll!important;
`;

const ImageContainer = styled.div`
  padding-top: 20px;
  height: 180px;
`;

const ServiceTitle = styled(Typography)`
  color: #224e9d !important;
  padding: 0 15px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold !important;
  line-height: 1.2em !important;
`;

const Description = styled(Typography)`
  color: #808faa !important;
  font-family: "Work Sans", sans-serif !important;
  margin-top: 25px !important;
  overflowY: scroll;
`;

const Divider = styled.div`
  background-color: blue;
  border-bottom: 1px solid white;
  width: 90%;
  margin-left: 5%;
`;

const PrimaryInfo = ({ title, children }) => (
  <div style={{ cursor: 'pointer' }}>
    <ImageContainer>{children}</ImageContainer>
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item>
        <ServiceTitle variant="h6">{title}</ServiceTitle>
      </Grid>
    </Grid>
  </div>
);

const SecondaryInfo = ({ title, description }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    style={{ padding: "15px 5px", overflowY: 'scroll' }}
  >
    <Grid item
      style={{ overflowY: 'scroll' }}>
      <ServiceTitle
        variant="h6"
        style={{ textAlign: "left", paddingLeft: 20, paddingTop: 15 }}
      >
        {title}
      </ServiceTitle>
    </Grid>
    <Divider />
    <Grid
      item
      className="carinside"
      style={{ textAlign: "left", paddingLeft: 20, paddingRight: 20, height: 215, overflowY: 'scroll' }}
    >
      <Description variant="body1"> {description}</Description>
    </Grid>
  </Grid>
);

const Service = ({ showPrimary, toggleInfo, title, children, description }) => (
  <Grid container justify="center" alignItems="center" className="service-card">
    <Grid item md={9} sm={12}>
      <CustomCard onClick={toggleInfo}>
        {showPrimary ? (
          <PrimaryInfo title={title} children={children} />
        ) : (
          <SecondaryInfo title={title} description={description} />
        )}
      </CustomCard>
    </Grid>
  </Grid>
);

Service.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  description: string.isRequired
};

export default compose(
  withStateHandlers(
    {
      showPrimary: true
    },
    {
      toggleInfo: ({ showPrimary }) => () => ({
        showPrimary: !showPrimary
      })
    }
  )
)(Service);
