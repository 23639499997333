import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import GDPText from './GDPTText';

import { Container, Divider, FooterContainer } from "../../atoms";

const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FooterContainer>
      <Divider />
      <Container container justify="space-between">
        <Grid item>
          <Typography component="h6" variant="h6" gutterBottom>
            centre medical lallange
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="h6"
            variant="h6"
            gutterBottom
            style={{ fontSize: 16 }}
          >
            {"\u00A9"} {new Date().getYear() + 1900}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h6" variant="h6" gutterBottom>
            <Button onClick={handleClickOpen}>Protection des données</Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <Typography
                  component="h6"
                  variant="h6"
                  style={{
                    textAlign: "center",
                    color: "#224e9d",
                    fontWeight: 600
                  }}
                >
                  FICHE D’INFORMATION A L’ATTENTION DU PATIENT CONCERNANT LA
                  PROTECTION DE SES DONNEES DE SANTE
                </Typography>
              </DialogTitle>
              <GDPText />
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Fermer
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </Grid>
      </Container>
    </FooterContainer>
  );
};
export default Footer;
