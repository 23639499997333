import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SectionTitle from "../SectionTitle";
import SectionDescription from "../SectionDescription";
import ChronicDisease from "../../assets/Services/ChronicDisease";
import FamilyPlanning from "../../assets/Services/FamilyPlanning";
import HomeVisits from "../../assets/Services/HomeVisits";
import MedicalCertificates from "../../assets/Services/MedicalCertificates";
import Prescription from "../../assets/Services/Prescription";
import Prevention from "../../assets/Services/Prevention";
import TravelMedicine from "../../assets/Services/TravelMedicine";
import Urgent from "../../assets/Services/Urgent";
import Service from "./Service";
import { MyContext as Language } from "../../context";
import { getContent } from "../../utils";

const ServicesContainer = styled(Grid)`
  text-align: center;
  background-color: #f3f6fa;
`;

const GeneralText = styled(Typography)`
  font-family: "WorkSans", sans-serif !important;
  color: #6f7b91 !important;
  line-height: 1.5em !important;
  font-weight: 500 !important;
  text-align: center;
  margin-top: 50px !important;
`;

const BoldText = styled(Typography)`
  font-family: "WorkSans", sans-serif !important;
  color: #6f7b91 !important;
  line-height: 1.5em !important;
  font-weight: bold !important;
`

const Services = () => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <ServicesContainer className="services" container justify="center">
          <SectionTitle text={getContent("services", "services", language)} />
          <Grid container justify="center" alignItems="center">
            <Grid item md={4} xs={10}>
              <GeneralText variant="h6">
                {getContent("services", "appointmentRequired", language)}.<br />
                Pour fixer un rendez-vous, vous pouvez cliquer sur {" "}
                <Link
                  to="/schedule"
                  style={{ textDecoration: "underline", color: "#6f7b91" }}
                >
                  ce lien
                </Link>
                {" "}ou contacter notre télésecrétariat au 26 17 50 68.
              </GeneralText>
            </Grid>
          </Grid>
          <Grid item>
            <SectionDescription text={getContent("services", "description", language)} />
          </Grid>
          <Grid
            container
            // spacing={24}
            direction="row"
            justify="center"
            alignItems="stretch"
          >
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "followUp", language)}
                description={getContent("services", "followUpDesc", language)}
              >
                <ChronicDisease />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "urgentConsultations", language)}
                description={getContent("services", "urgentConsultationsDesc", language)}
              >
                <Urgent />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "prevention", language)}
                description={getContent("services", "preventionDesc", language)}
              >
                <Prevention />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "familyPlanning", language)}
                description={getContent("services", "familyPlanningDesc", language)}
              >
                <FamilyPlanning />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "travelMedicine", language)}
                description={getContent("services", "travelMedicineDesc", language)}
              >
                <TravelMedicine />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "medicalCertificates", language)}
                description={getContent("services", "medicalCertificatesDesc", language)}
              >
                <MedicalCertificates />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "prescription", language)}
                description={
                  <>{getContent("services", "prescriptionDesc", language)} <br /> <BoldText>{getContent("services", "prescriptionDesc2", language)}</BoldText></>}
              >
                <Prescription />
              </Service>
            </Grid>
            <Grid item lg={3} md={4} sm={8} xs={10}>
              <Service
                title={getContent("services", "teleconsultations", language)}
                description={getContent("services", "teleconsultationsDesc", language)}
              >
                <HomeVisits />
              </Service>
            </Grid>
          </Grid>

        </ServicesContainer>
      );
    }}
  </Language.Consumer >
);

export default Services;
