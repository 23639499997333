import styled from "styled-components";
import { Card, CardActions, CardMedia, FormControlLabel, Grid, Link, Paper, Typography } from "@material-ui/core";

export const Title = styled(Typography)`
  color: #224e9d !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 600 !important;
  text-transform: uppercase;
`;

export const Bold = styled(Typography)`
  color: #6f7b91 !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: bold !important;
`;

export const Button = styled.button`
  background-color: #224e9d;
  padding: 15px 20px;
  margin-left: 30px;
  border: none;
  a {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    color: white !important;
    text-decoration: none;
  };
  cursor: pointer;
  &:disabled {
    background-color: gray!important
    cursor: auto;
  }
`;

export const CardInfo = styled(CardActions)`
  background-color: white;
  text-align: center;
  padding: 25px 0 10px 0;
  height: 150px;

  h4 {
    font-family: "Montserrat", sans-serif;
    color: #224e9d;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: normal;
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    line-height: 2.12;
    color: #808faa;
  }
`;

export const ContentText = styled(Typography)`
  color: #6f7b91 !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
`;

export const Divider = styled.div`
  border-top: 1px solid #224e9d;
  width: 90%;
  margin-left: 5%;
`;

export const EmergencyWrapper = styled.div`
  margin: 0;
  background-color: #eaf0f4;
  background: #eaf0f4;
  padding-bottom: 50px;
`;

export const LinkText = styled(Link)`
  color: #224e9d !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
`;

export const ButtonText = styled(Typography)`
  color: white !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 600 !important;
`;

export const Subtitle = styled(Typography)`
  color: #224e9d !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 600 !important;
  text-align: left;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: #224e9d !important;
  font-family: "Work Sans", sans-serif !important;
`;

export const Description = styled(Typography)`
  color: #808faa !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 15px !important;
`;

export const ServicesContainer = styled.div`
  text-align: left;
  padding-bottom: 100px;
`;

export const SubTitle = styled(Typography)`
  font-family: "Work Sans", sans-serif !important;
  color: #808faa !important;
  font-weight: bold !important;
  text-decoration: underline;
  text-decoration-color: #d4494e;
  padding-bottom: 5px;
  margin-top: 30px !important;
`;

export const SubTitleSmall = styled(Typography)`
  font-family: "Work Sans", sans-serif !important;
  color: #808faa !important;
  font-weight: 300 !important;
  margin-top: 30px !important;
`;

export const ConsultationContext = styled(Paper)`
  font-family: "WorkSans", sans-serif;
  background-color: #d4494e !important;
  min-height: 150px;
  text-align: left;
  min-width: 90%;
  padding: 20px;
`;

export const ClinicName = styled(Typography)`
  font-weight: 600 !important;
  color: white !important;
`;

export const City = styled(Typography)`
  color: white !important;
  font-weight: 400 !important;
`;

export const Address = styled(Typography)`
  color: white !important;
  font-weight: 400 !important;
  margin-top: 30px !important;
`;

export const BigText = styled(Typography)`
  font-family: "Work Sans", sans-serif !important;
  text-align: right;
  font-weight: bold !important;
  color: #224e9d !important;
  padding-right: 10px !important;
`;

export const Information = styled(Typography)`
  font-family: "Work Sans", sans-serif !important;
  color: #808faa !important;
  text-align: left;
`;

export const ImportantTitle = styled(Typography)`
  font-family: "Work Sans", sans-serif !important;
  font-weight: 600 !important;
  color: #d4494e !important;
  padding: 18px 0;
`;

export const ImportantContent = styled(Typography)`
  a  {
    font-family: "Work Sans", sans-serif;
    font-size: 22px;
    line-height: 0px;
    color: #808faa !important;
  }
`;

export const ContentWrapper = styled.div`
  #alert-dialog-description h6 {
    color: #224e9d;
    font-weight: 600;
  }

  p {
    color: #6f7b91;
  }

  ul li div span {
    color: #6f7b91;
  }
`;


export const FooterContainer = styled.div`
  width: 95%;
  margin-left: 2.5%;
  margin-top 50px;
`;

export const Container = styled(Grid)`
  h6 {
    min-height: 50px;
    margin-top: 25px;
    font-family "Work Sans", sans-serif;
    color: #808faa;
  }

  h6>button>span {
    font-family "Work Sans", sans-serif!important;
    color: #808faa!important;
    font-size: 16px!important;
    margin-top: -5px;
    text-transform: capitalize!important;
  }
`;

export const SchedulingWrapper = styled.div`
  margin: 0;
  background-color: #eaf0f4;
  background: #eaf0f4;
  padding-bottom: 50px;
  min-height: calc(100vh - 200px);
  height: 100%;
  padding-top: 100px;
`;



export const CardImage = styled(CardMedia)`
height: 1200px;
`;

export const TeamCardInfo = styled(CardActions)`
background-color: #224e9d;
text-align: center;
padding: 25px 0 10px 0;
height: 150px;

h5,
  h4 {
  color: white!important;
  font-family: "Montserrat", sans-serif;
}

  h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: normal;
}

  h5 {
  font-family: Montserrat;
  font-size: 17px;
  line-height: 2.12;
  color: #ffffff;
}
`;



export const TeamCardImageSmall = styled(CardMedia)`
height: 286px;
width: 286px;
border-radius: 100 %;
`;


export const TeamDividerBlue = styled.div`
background-color: #224e9d;
border: 1px solid #224e9d;
width: 90 %;
margin-left: 5 %;
`;

export const TeamTitle = styled(Typography)`
font-family: "Montserrat", sans-serif!important;
line-height: 1.2em!important;
color: #224e9d!important;
font-weight: bold!important;
padding: 15px 0 5px 0;
`;

export const TeamSubtitle = styled(Typography)`
color: #808faa!important;
font-family: "Montserrat", sans-serif!important;
font-weight: normal!important;
line-height: 2.13!important;
`;

export const TeamMainText = styled(Typography)`
font-family: "WorkSans", sans-serif!important;
font-size: 14px;
font-weight: ${props =>
    props.type === "title" ? "bold" : "normal"
  } !important;
font-style: normal;
font-stretch: normal;
line-height: 1.29em;
letter-spacing: normal;
text-align: center;
color: ${props =>
    props.type === "title" ? "#224e9d" : "#808faa"
  } !important;
`;

export const CustomCard = styled(Card)`
  height: ${window.innerWidth < 700 ? "75vh" : "700px"};
  margin-left: ${window.innerWidth < 700 ? "25px" : ""};
  margin-right: ${window.innerWidth < 700 ? "25px" : ""};
  margin-top: 50px;
`;