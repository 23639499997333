import React, { useState } from "react";
import { Grid, TextField, FormControl, RadioGroup, Radio } from "@material-ui/core";
import { Button, ButtonText, SchedulingWrapper, StyledFormControlLabel, Subtitle, Title } from '../../atoms';
import MedicineForm from "./MedicineForm";

const Delivery = ({ sendEmail, form }) => {
  const [formIsFilled, setFormIsFilled] = useState(false);
  const isMobile = window.innerWidth < 768;
  const formData = {};
  const handleFormChange = (type, value) => {
    formData[type] = value;
    if (Object.keys(formData).length === 7) {
      setFormIsFilled(true)
    }
  }

  return (
    <SchedulingWrapper style={{ overflowX: "hidden" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={8} sm={12} style={{ backgroundColor: 'white', width: '100%', textAlign: 'center', padding: '50px', color: '#6f7b91 !important' }}>
          <form ref={form}>
            <Title variant="h6">
              Renouvellement d'Ordonnance
            </Title>
            <Grid item md={8} sm={12} style={{ marginTop: '30px' }}>
              <TextField variant="standard" label="Nom" name="last_name" style={{ width: '90%' }} onChange={(e) => handleFormChange('nom', e.target.value)} />
            </Grid>
            <Grid item md={8} sm={12}>
              <TextField variant="standard" label="Prénom" name="first_name" style={{ width: '90%' }} onChange={(e) => handleFormChange('prenom', e.target.value)} />
            </Grid>
            <Grid item md={8} sm={12}>
              <TextField variant="standard" label="Matricule de la CNS" name="matricule" style={{ width: '90%' }} onChange={(e) => handleFormChange('cns', e.target.value)} />
            </Grid>
            <Grid container style={{ marginTop: '50px', marginLeft: '25px' }}>
              <FormControl>
                <Subtitle>
                  Médecin traitant
                </Subtitle>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  style={{ color: '#6f7b91 !important' }}
                  name="doctor"
                  onChange={(e) => handleFormChange('doctor', e.target.value)}
                >
                  <StyledFormControlLabel value="dr.daflor@gmail.com" control={<Radio />} label="Dr DA FLORENCIA RICARDO David" style={{ color: '#6f7b91 !important' }} />
                  <StyledFormControlLabel value="dr.camilleleveque@gmail.com" control={<Radio />} label="Dr LEVEQUE Camille" style={{ color: '#6f7b91 !important' }} />
                  <StyledFormControlLabel value="dr.ribeirofreitas@gmail.com" control={<Radio />} label="Dr RIBEIRO FREITAS Ana Paulina" style={{ color: '#6f7b91 !important' }} />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container={isMobile ? false : true}>
              <Grid item md={10} sm={12} style={{ marginTop: '30px' }}>
                <TextField variant="standard" label="Adresse" name="address" style={{ width: '95%' }} onChange={(e) => handleFormChange('address', e.target.value)} />
              </Grid>
              <Grid item md={5} sm={12} style={{ marginTop: '30px' }}>
                <TextField variant="standard" label="Code Postal" name="post_code" style={{ width: '90%' }} onChange={(e) => handleFormChange('postcode', e.target.value)} />
              </Grid>
              <Grid item md={5} sm={12} style={{ marginTop: '30px' }}>
                <TextField variant="standard" label="Ville" name="city" style={{ width: '90%' }} onChange={(e) => handleFormChange('city', e.target.value)} />
              </Grid>
            </Grid>

            <Grid container style={!isMobile ? { marginTop: '50px', marginLeft: '25px' } : { marginTop: "50px" }}>
              <MedicineForm />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '50px', textAlign: 'center' }}
            >
              <Grid item md={12}>
                <Button onClick={sendEmail} disabled={!formIsFilled}>
                  <ButtonText>
                    Envoyer
                  </ButtonText>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </SchedulingWrapper>
  );
};


export default Delivery;
