import React from "react";
import { CardActionArea, Grid } from "@material-ui/core";
import { CardImage, Divider, TeamCardInfo } from "../../atoms";
import Typography from "@material-ui/core/Typography";


const TeamCardPrimary = ({ title, subtitle, image }) => (
  <CardActionArea>
    <CardImage image={image} />
    <TeamCardInfo>
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" component="h4">
            {title}
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h5">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </TeamCardInfo>
  </CardActionArea>
);

export default TeamCardPrimary;
