import React from "react";
import { Grid } from "@material-ui/core";
import {
  Bold,
  Button,
  ButtonText,
  ContentText,
  SchedulingWrapper,
  LinkText,
  Title,
} from '../../atoms';

const PrescriptionRenewalDisclaimer = ({ nextStep }) => {
  return (
    <SchedulingWrapper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={8} style={{ backgroundColor: 'white', width: '100%', textAlign: 'center', padding: '50px', color: '#6f7b91 !important' }}>
          <Title variant="h6">
            Renouvellement d'Ordonnance
          </Title>
          <ContentText style={{ marginTop: '50px' }}>
            Formulaire de contact pour demande d'ordonnances de renouvellement d'un traitement chronique habituel
          </ContentText>
          <ContentText style={{ marginTop: '50px', fontWeight: 'bold !important', color: 'red !important' }}>
            <Bold>
              Attention: Cela concerne uniquement les patients connus de notre équipe médicale, pour un renouvellement de traitement habituel.
              Pout toute autre demande, une consultation est obligatoire
            </Bold>
          </ContentText>
          <div style={{ marginTop: "30px" }}></div>
          <a href="/">
            <LinkText to="/">
              Cancel
            </LinkText>
          </a>
          <Button>
            <ButtonText onClick={nextStep}>
              Next
            </ButtonText>
          </Button>
        </Grid>
      </Grid>
    </SchedulingWrapper>
  );
}

export default PrescriptionRenewalDisclaimer;
