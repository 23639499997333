import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { MyProvider } from "./context";
import Content from './components/Content';

class App extends Component {
  render() {
    return (
      <MyProvider>
        <BrowserRouter>
          <Content />
        </BrowserRouter>
      </MyProvider>
    );
  }
}

export default App;
