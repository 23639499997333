import React from "react";

const HomeVisits = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.164 18.162"
    enableBackground="new 0 0 18.164 18.162"
  >
    <g>
      <path
        fill="#DF6161"
        d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.059,0.035,0.117,0.068,0.176,0.104
		c0.152,0.089,0.309,0.174,0.467,0.256c0.026,0.014,0.052,0.03,0.078,0.043c0.016,0.008,0.031,0.015,0.047,0.022
		c0.085,0.042,0.172,0.083,0.258,0.122c0.046,0.021,0.093,0.038,0.139,0.058c0.133,0.058,0.266,0.115,0.402,0.167
		c0.055,0.021,0.11,0.04,0.166,0.061c0.052,0.019,0.104,0.039,0.156,0.057c0.197,0.066,0.396,0.123,0.6,0.176
		c0.098,0.025,0.193,0.055,0.293,0.078c0.019,0.004,0.039,0.006,0.058,0.01c0.282,0.062,0.569,0.116,0.862,0.151
		c0.045,0.005,0.093,0.004,0.138,0.009c0.31,0.033,0.62,0.061,0.938,0.061c0.316,0,0.629-0.017,0.938-0.049
		c0.34-0.035,0.673-0.093,1-0.165c0.385-0.084,0.757-0.199,1.122-0.331c0.179-0.064,0.36-0.125,0.534-0.2
		c0.122-0.053,0.239-0.114,0.357-0.173c0.026-0.013,0.053-0.026,0.08-0.039c0.035-0.017,0.069-0.033,0.104-0.05
		c0.008-0.004,0.015-0.009,0.021-0.013c0.206-0.107,0.396-0.237,0.592-0.358c0.52-0.322,1.016-0.677,1.461-1.094
		c1.764-1.658,2.873-4.004,2.873-6.61C18.164,4.073,14.09,0,9.082,0z M15.236,14.158c-0.553,0.67-1.219,1.24-1.957,1.7
		c-0.014,0.008-0.027,0.015-0.04,0.023c-0.042,0.024-0.084,0.048-0.126,0.072c-0.026,0.015-0.054,0.03-0.08,0.046
		c-0.096,0.056-0.189,0.114-0.289,0.166c-0.154,0.081-0.318,0.144-0.479,0.212c-0.041,0.017-0.082,0.035-0.122,0.054
		c-0.058,0.027-0.115,0.055-0.176,0.078c-0.232,0.087-0.473,0.16-0.714,0.228c-0.054,0.015-0.105,0.036-0.159,0.05
		c-0.025,0.006-0.05,0.014-0.074,0.021c-0.042,0.012-0.083,0.023-0.125,0.036c-0.057,0.017-0.112,0.036-0.17,0.048
		c-0.172,0.036-0.348,0.059-0.523,0.083c-0.019,0.003-0.038,0.002-0.057,0.004c-0.041,0.006-0.084,0.004-0.125,0.01
		c-0.308,0.04-0.619,0.074-0.938,0.074c-0.297,0-0.59-0.019-0.881-0.051C8.182,17.01,8.163,17.005,8.144,17
		c-0.042-0.008-0.083-0.018-0.125-0.024c-0.254-0.036-0.504-0.086-0.75-0.145c-0.041-0.01-0.083-0.017-0.125-0.024
		c-0.033-0.005-0.066-0.01-0.099-0.019c-0.012-0.003-0.022-0.007-0.033-0.01v0c-0.239-0.064-0.473-0.141-0.701-0.226
		c-0.039-0.014-0.075-0.031-0.113-0.045c-0.055-0.021-0.107-0.046-0.16-0.07c-0.041-0.019-0.084-0.036-0.125-0.054
		c-0.096-0.041-0.192-0.079-0.286-0.124c-0.036-0.018-0.072-0.036-0.108-0.054c-0.017-0.008-0.032-0.019-0.049-0.027
		c-0.135-0.069-0.266-0.144-0.396-0.219c-0.041-0.024-0.084-0.044-0.124-0.069l-0.001,0c-0.019-0.011-0.037-0.022-0.055-0.034
		c-0.207-0.128-0.407-0.264-0.6-0.409v0.001C2.357,13.992,1.1,11.685,1.1,9.081C1.1,4.68,4.682,1.1,9.082,1.1
		c4.402,0,7.982,3.58,7.982,7.981C17.064,11.008,16.377,12.778,15.236,14.158z"
      />
      <path
        fill="#4360AC"
        d="M14.299,8.913l-3.979-3.979C9.99,4.604,9.551,4.423,9.08,4.423c-0.445,0-0.869,0.168-1.204,0.48
		L7.122,5.657L5.235,7.542L3.863,8.913c-0.505,0.503-0.652,1.217-0.385,1.863c0.258,0.621,0.826,1.05,1.469,1.121v3.992v1.26
		c0.026,0.014,0.052,0.03,0.078,0.043c0.016,0.008,0.031,0.015,0.047,0.022c0.085,0.042,0.172,0.083,0.258,0.122
		c0.046,0.021,0.093,0.038,0.139,0.058c0.133,0.058,0.266,0.115,0.402,0.167c0.055,0.021,0.11,0.04,0.166,0.061
		c0.052,0.019,0.104,0.039,0.156,0.057c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.293,0.078
		c0.019,0.004,0.039,0.006,0.058,0.01c0.282,0.062,0.569,0.116,0.862,0.151c0.045,0.005,0.093,0.004,0.138,0.009
		c0.31,0.033,0.62,0.061,0.938,0.061c0.316,0,0.629-0.017,0.938-0.049c0.34-0.035,0.673-0.093,1-0.165
		c0.385-0.084,0.757-0.199,1.122-0.331c0.179-0.064,0.36-0.125,0.534-0.2c0.122-0.053,0.239-0.114,0.357-0.173
		c0.026-0.013,0.053-0.026,0.08-0.039c0.035-0.017,0.069-0.033,0.104-0.05c0.008-0.004,0.015-0.009,0.021-0.013l0.001-1.262
		l0.001-3.988c0.632-0.079,1.19-0.506,1.443-1.117C14.951,10.131,14.805,9.417,14.299,8.913z M10.02,16.989
		c-0.308,0.04-0.619,0.074-0.938,0.074c-0.297,0-0.59-0.019-0.881-0.051C8.182,17.01,8.163,17.005,8.144,17v-3.848h1.876V16.989z
		 M13.675,10.359c-0.107,0.26-0.371,0.45-0.659,0.45c-0.006,0-0.015,0-0.022-0.002l-0.35-0.411
		c-0.173-0.202-0.499-0.231-0.705-0.057c-0.21,0.178-0.235,0.495-0.058,0.705l0.268,0.314l-0.006,5.073
		c-0.058,0.027-0.115,0.055-0.176,0.078c-0.232,0.087-0.473,0.16-0.714,0.228c-0.054,0.015-0.105,0.036-0.159,0.05
		c-0.025,0.006-0.05,0.014-0.074,0.021v-4.155c0-0.276-0.225-0.5-0.5-0.5H7.644c-0.275,0-0.5,0.224-0.5,0.5v4.155
		c-0.033-0.005-0.066-0.01-0.099-0.019c-0.012-0.003-0.022-0.007-0.033-0.01v0c-0.239-0.064-0.473-0.141-0.701-0.226
		c-0.039-0.014-0.075-0.031-0.113-0.045c-0.055-0.021-0.107-0.046-0.16-0.07V11.23l0.149-0.175c0.178-0.21,0.152-0.526-0.059-0.705
		c-0.204-0.172-0.531-0.143-0.704,0.058l-0.346,0.406c-0.247-0.024-0.489-0.208-0.592-0.455c-0.058-0.139-0.115-0.412,0.147-0.674
		l0.62-0.621l3.358-3.359c0.015-0.015,0.036-0.021,0.052-0.033C8.914,5.47,9.32,5.476,9.548,5.704l3.98,3.98
		C13.791,9.946,13.733,10.219,13.675,10.359z"
      />
      <path
        fill="#563D60"
        d="M13.113,15.954c-0.026,0.015-0.054,0.03-0.08,0.046c-0.096,0.056-0.189,0.114-0.289,0.166
		c-0.154,0.081-0.318,0.144-0.479,0.212c-0.041,0.017-0.082,0.035-0.122,0.054c-0.058,0.027-0.115,0.055-0.176,0.078
		c-0.232,0.087-0.473,0.16-0.714,0.228c-0.054,0.015-0.105,0.036-0.159,0.05c-0.025,0.006-0.05,0.014-0.074,0.021
		c-0.042,0.012-0.083,0.023-0.125,0.036c-0.057,0.017-0.112,0.036-0.17,0.048c-0.172,0.036-0.348,0.059-0.523,0.083
		c-0.019,0.003-0.038,0.002-0.057,0.004c-0.041,0.006-0.084,0.004-0.125,0.01c-0.308,0.04-0.619,0.074-0.938,0.074
		c-0.297,0-0.59-0.019-0.881-0.051C8.182,17.01,8.163,17.005,8.144,17c-0.042-0.008-0.083-0.018-0.125-0.024
		c-0.254-0.036-0.504-0.086-0.75-0.145c-0.041-0.01-0.083-0.017-0.125-0.024c-0.033-0.005-0.066-0.01-0.099-0.019
		c-0.012-0.003-0.022-0.007-0.033-0.01v0c-0.239-0.064-0.473-0.141-0.701-0.226c-0.039-0.014-0.075-0.031-0.113-0.045
		c-0.055-0.021-0.107-0.046-0.16-0.07c-0.041-0.019-0.084-0.036-0.125-0.054c-0.096-0.041-0.192-0.079-0.286-0.124
		c-0.036-0.018-0.072-0.036-0.108-0.054c-0.017-0.008-0.032-0.019-0.049-0.027c-0.135-0.069-0.266-0.144-0.396-0.219
		c-0.041-0.024-0.084-0.044-0.124-0.069l-0.001,0v1.26c0.026,0.014,0.052,0.03,0.078,0.043c0.016,0.008,0.031,0.015,0.047,0.022
		c0.085,0.042,0.172,0.083,0.258,0.122c0.046,0.021,0.093,0.038,0.139,0.058c0.133,0.058,0.266,0.115,0.402,0.167
		c0.055,0.021,0.11,0.04,0.166,0.061c0.052,0.019,0.104,0.039,0.156,0.057c0.197,0.066,0.396,0.123,0.6,0.176
		c0.098,0.025,0.193,0.055,0.293,0.078c0.019,0.004,0.039,0.006,0.058,0.01c0.282,0.062,0.569,0.116,0.862,0.151
		c0.045,0.005,0.093,0.004,0.138,0.009c0.31,0.033,0.62,0.061,0.938,0.061c0.316,0,0.629-0.017,0.938-0.049
		c0.34-0.035,0.673-0.093,1-0.165c0.385-0.084,0.757-0.199,1.122-0.331c0.179-0.064,0.36-0.125,0.534-0.2
		c0.122-0.053,0.239-0.114,0.357-0.173c0.026-0.013,0.053-0.026,0.08-0.039c0.035-0.017,0.069-0.033,0.104-0.05
		c0.008-0.004,0.015-0.009,0.021-0.013l0.001-1.262C13.197,15.906,13.155,15.93,13.113,15.954z"
      />
      <path
        fill="#DF6161"
        d="M7.122,4.15c0-0.521-0.422-0.942-0.942-0.942S5.238,3.629,5.238,4.15v1.507H3.763
		c-0.521,0-0.942,0.422-0.942,0.942c0,0.521,0.422,0.942,0.942,0.942h1.473l1.887-1.885V4.15z"
      />
      <path
        fill="#DF6161"
        d="M5.254,9.063C5.295,9.546,5.687,9.928,6.18,9.928c0.521,0,0.942-0.421,0.942-0.942V7.542h1.476
		c0.521,0,0.942-0.421,0.942-0.942c0-0.497-0.388-0.892-0.876-0.928C8.648,5.684,8.627,5.689,8.612,5.704L5.254,9.063z"
      />
      <path
        fill="#563D60"
        d="M7.122,5.657L7.122,5.657L5.235,7.542h0.003v1.444c0,0.028,0.014,0.051,0.016,0.078l3.358-3.359
		c0.015-0.015,0.036-0.021,0.052-0.033C8.642,5.669,8.621,5.657,8.598,5.657H7.122z"
      />
    </g>
  </svg>
);

export default HomeVisits;
