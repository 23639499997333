import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, Grid, CardActionArea, CardMedia } from "@material-ui/core";
import { CardInfo, Divider } from "../../atoms";
import david from "../../assets/Team/david.jpg";
import paulina from "../../assets/Team/paulina.jpg";
import camille from "../../assets/Team/camille.jpg";

const TeamCard = ({ image, title, subtitle }) => (
  <Card>
    <CardActionArea>
      <CardMedia image={image} style={{ transform: "scale(1.2)", height: "400px", width: "350px", backgroundPosition: "top" }} />
      <CardInfo>
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" component="h4" style={{ paddingTop: "50px" }}>
              {title}
            </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="h5">
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </CardInfo>
    </CardActionArea>
  </Card>
);

const ScheduleAppointment = () => (
  <div
    className="schedule-appointment"
    style={{
      backgroundColor: "#f3f6fa",
      minHeight: "calc(100vh - 100px)",
      // marginTop: "-50px",
      paddingTop: "50px"
    }}
  >
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      style={{ padding: 50 }}
    >
      <Grid
        item
        className="doctor-card"
        lg={3}
        md={4}
        sm={5}
        xs={12}
        style={{ marginBottom: 50 }}

      >
        <a
          href='https://secure.ubicentrex.net/dispo_medecin.php?n=69503137&nsoc=13959'
          webagcli-id="69503137"
          webagsoc-id="13959"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TeamCard
            title="Dr da Florência David"
            subtitle="General Practitioner since 2014"
            image={david}
          />
        </a>
      </Grid>
      <Grid
        item
        lg={3}
        md={4}
        sm={5}
        xs={12}
        className="doctor-card"
      >
        <a className='webagdispo_frame'
          href='https://secure.ubicentrex.net/dispo_medecin.php?n=45060577&nsoc=13959'
          webagcli-id="45060577"
          webagsoc-id="13959"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TeamCard
            title="Ribeiro Freitas Ana Paulina"
            subtitle="General Practitioner since 2017"
            image={paulina}
          />
        </a>
      </Grid>

      <Grid
        item
        lg={3}
        md={4}
        sm={5}
        xs={12}
        className="doctor-card"
      >
        <a className='webagdispo_frame'
          href='https://www.doctena.lu/en/doctor/Camille_LEVEQUE-725703#14370'
          webagcli-id="45060577"
          webagsoc-id="13959"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TeamCard
            title="Dr Camille LEVEQUE"
            subtitle="General Practitioner since 2017"
            image={camille}
          />
        </a>
      </Grid>
    </Grid>
  </div >
);

export default ScheduleAppointment;
