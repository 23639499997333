import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { MyContext as Language } from "../../context";
import { WatchLater } from "@material-ui/icons";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getContent } from "../../utils";
import bg from "../../assets/Home/HomeMobile.png";


const Title = styled(Typography)`
  width: 342px;
  height: 233px;
  font-family: "Ubuntu", sans-serif !important;
  font-size: 65px !important;
  font-weight: bold !important;
  line-height: 1.23 !important;
  letter-spacing: normal !important;
  background-color: #224e9d;
  text-transform: uppercase;
  padding: 20px 50px;
  color: white !important;
`;

const Description = styled(Typography)`
  color: #808faa !important;
  font-family: "Work Sans", sans-serif !important;
  margin-top: 25px !important;
`;

const HomeContainer = styled(Grid)`
  min-height: 950px;
`;

const Button = styled.button`
  background-color: #224e9d;
  padding: 15px 20px !important;
  margin-top: 30px;
  border: none;

  a {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    color: white !important;
    text-decoration: none;
    text-transform: uppercase;
  }
`;

const BigTitle = styled(Typography)`
  font-family "Montserrat", sans-serif!important;
  color: white!important;
  font-weight: 600!important;
  margin: 2px 0 0 10px !important;
  text-transform: uppercase;
`;

const TitleSmall = styled(Typography)`
  font-family "Work Sans", sans-serif !important;
  font-weight: bold !important;
  color: #224e9d !important;
  padding: 12px 0 0 15px !important;
`;

const Content = styled(Typography)`
  font-family "Work Sans", sans-serif !important;
  color: #224e9d !important;
  padding-left: 15px !important;
  line-height: 14px!important;
`;

const TitleContainer = styled(ExpansionPanelSummary)`
  background-color: #d4494e !important;
  padding: 12px 15px;
  display: flex;
  align-items: center;
`;

const ScheduleDetails = styled(ExpansionPanelDetails)`
  display: block !important;
  padding: 0px 100px 20px 0 !important;
`;

const HomeDesktop = ({ language, openModal }) => {
  return (
    <HomeContainer
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      className="home"
      style={{ paddingTop: 150 }}
    >
      <div className="hidden" style={{ height: 400 }} />
      <Grid item md={4}>
        <Title variant="h2" component="h2">
          {getContent("home", "centreMedicalLallange", language)}
        </Title>
        <Grid item md={8}>
          <Description variant="body1" component="p">
            {getContent("home", "description", language)}
          </Description>
        </Grid>
        <Button>
          <Link to="/schedule">{getContent("home", "scheduleAppointment", language)}</Link>
        </Button>
      </Grid>
      <Grid item md={8}>
        <Grid container justify="center">
          <Grid item md={7} />
          <Grid item md={5}>
            <ExpansionPanel style={{ marginTop: 400 }}>
              <TitleContainer
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <WatchLater style={{ color: "white" }} />
                <BigTitle variant="body1">
                  {getContent("home", "schedules", language)}
                </BigTitle>
              </TitleContainer>
              <ScheduleDetails>
                <TitleSmall variant="body1">
                  {getContent("home", "secretariat", language)}
                </TitleSmall>
                <Content> {getContent("home", "mondayToFriday", language)}</Content>
                <Content>
                  {getContent("home", "secretariatScheduleStart", language)} <br />{" "}
                  {getContent("home", "secretariatScheduleStart2", language)} <br />{" "}
                  {getContent("home", "secretariatScheduleEnd", language)}
                </Content>
                <Content style={{ marginTop: "10px" }}>
                  {getContent("home", "allConsultationsRequireAnAppointment", language)}
                  <br />
                  {getContent("home", "scheduleUrgent", language)}
                  <br />
                  <div style={{ marginTop: "10px" }}>
                    {getContent("home", "scheduleUrgent2", language)}
                  </div>
                </Content>
              </ScheduleDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Grid>
    </HomeContainer>
  );
}

const HomeMobile = ({ language }) => {
  return (
    <div className="home-mobile" style={{ marginTop: 100, paddingBottom: 50 }}>
      <ExpansionPanel style={{ position: "absolute" }}>
        <TitleContainer
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          <WatchLater style={{ color: "white" }} />
          <BigTitle variant="body1">{getContent("home", "schedules", language)}</BigTitle>
        </TitleContainer>
        <ScheduleDetails>
          <TitleSmall variant="body1">
            {getContent("home", "officeHours", language)}
          </TitleSmall>
          <Content variant="body1">
            {getContent("home", "mondayToFriday", language)}
          </Content>
          <Content>
            {getContent("home", "officeScheduleStart", language)} <br />
            {getContent("home", "officeScheduleEnd", language)}
          </Content>
          <TitleSmall variant="body1">
            {getContent("home", "secretariat", language)}
          </TitleSmall>
          <Content> {getContent("home", "mondayToFriday", language)}</Content>
          <Content>
            {getContent("home", "secretariatScheduleStart", language)} <br />{" "}
            {getContent("home", "secretariatScheduleEnd", language)}
          </Content>
          <Content style={{ marginTop: "10px" }}>
            {getContent("home", "allConsultationsRequireAnAppointment", language)}
            <br />
            {getContent("home", "scheduleUrgent", language)}
          </Content>
        </ScheduleDetails>
      </ExpansionPanel>
      <img src={bg} alt="background" style={{ width: "100%", marginTop: 50 }} />
      <Grid container justify="center">
        <Grid item xs={10}>
          <Description variant="h6">
            {getContent("home", "description", language)}
          </Description>
        </Grid>
        <Button>
          <Link to="/schedule">{getContent("home", "scheduleAppointment", language)}</Link>
        </Button>
      </Grid>
    </div>
  );
}

const Home = () => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <Fragment>
          <HomeDesktop className="home" language={language} />
          <HomeMobile language={language} />
        </Fragment>
      );
    }}
  </Language.Consumer>
);

export default Home;
