import React from "react";

const Prescription = () => (
  <svg
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.164 18.162"
    enableBackground="new 0 0 18.164 18.162"
  >
    <g>
      <g>
        <g>
          <path
            fill="#DF6161"
            d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.058,0.035,0.117,0.068,0.176,0.104
				c0.178,0.104,0.358,0.206,0.544,0.299c0.101,0.051,0.203,0.098,0.306,0.145c0.178,0.08,0.357,0.155,0.54,0.225
				c0.106,0.041,0.214,0.081,0.323,0.117c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.292,0.078
				c0.302,0.067,0.608,0.124,0.921,0.161c0.354,0.042,0.711,0.069,1.076,0.069c1.276,0,2.49-0.268,3.594-0.744
				c0.185-0.08,0.363-0.17,0.541-0.262c0.214-0.109,0.41-0.246,0.612-0.371c0.521-0.322,1.017-0.677,1.461-1.094
				c1.765-1.658,2.874-4.004,2.874-6.61C18.164,4.073,14.09,0,9.082,0z M15.236,14.158c-0.553,0.67-1.219,1.24-1.958,1.7
				c-0.175,0.108-0.351,0.212-0.534,0.307c-0.25,0.13-0.51,0.242-0.776,0.343c-0.233,0.087-0.474,0.16-0.716,0.229
				c-0.055,0.015-0.105,0.036-0.158,0.05h0.061l0,0h-0.061c-0.125,0.032-0.244,0.079-0.37,0.105
				c-0.171,0.036-0.347,0.059-0.522,0.083c-0.366,0.053-0.738,0.088-1.119,0.088c-0.298,0-0.591-0.019-0.881-0.051
				c-0.396-0.044-0.779-0.124-1.156-0.224c-0.012-0.003-0.022-0.008-0.034-0.011l0,0c-0.237-0.064-0.47-0.141-0.698-0.226
				c-0.041-0.015-0.08-0.032-0.121-0.048C6,16.429,5.812,16.348,5.627,16.259c-0.037-0.019-0.074-0.037-0.11-0.056
				c-0.192-0.098-0.382-0.201-0.565-0.313c-0.021-0.012-0.041-0.023-0.061-0.036c-0.205-0.128-0.405-0.263-0.598-0.408v0.001
				C2.357,13.991,1.1,11.685,1.1,9.081C1.1,4.68,4.681,1.1,9.082,1.1s7.982,3.58,7.982,7.981
				C17.064,11.008,16.377,12.778,15.236,14.158z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#DF6161"
            points="12.859,13.094 12.859,13.094 12.859,13.094 			"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#DF6161"
            d="M8.091,9.627c0.951,0,1.903,0,2.854,0c0.025,0,0.051,0,0.076,0c0.127,0,0.253,0,0.381,0
				c0.191,0,0.255-0.171,0.221-0.314c-0.025-0.099-0.088-0.188-0.221-0.188c-0.391,0-0.779,0-1.17,0c-0.714,0-1.428,0-2.142,0
				C7.766,9.124,7.766,9.627,8.091,9.627z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#DF6161"
            d="M10.982,9.947c-0.824,0-1.648,0-2.473,0c-0.324,0-0.324,0.504,0,0.504c0.824,0,1.648,0,2.473,0
				C11.307,10.451,11.307,9.947,10.982,9.947z"
          />
        </g>
      </g>
      <path
        fill="#DF6161"
        d="M13.473,5.377c-0.07-0.738-0.584-1.266-1.305-1.372c-0.056-0.009-0.104-0.033-0.161-0.037
		c-0.508,0-1.017,0-1.523,0c-1.665,0-3.33,0-4.995,0C4.652,4.01,4.049,4.612,4.006,5.449C3.998,5.622,4.084,5.729,4.199,5.783
		c0.057,0.038,0.123,0.061,0.205,0.061c0.589,0,1.178,0,1.768,0c0,0.82,0,2.401,0,3.989c0,0.9,0,1.803,0,2.569
		c0,0.037,0,0.068,0,0.105l0.018,0.023c-0.005,0.004-0.012,0.005-0.018,0.008c0,0.185,0,0.368,0,0.533
		c0.023,0.032,0.045,0.065,0.083,0.093c0.069,0.05,0.193,0.05,0.263,0c0.093-0.064,0.186-0.13,0.279-0.195
		c0.058-0.04,0.115-0.08,0.173-0.121c0,0.003,0,0.008,0,0.011c0.337-0.238,0.677-0.478,1.014-0.719
		c0.009,0.005,0.013,0.006,0.021,0.01c0.072,0.052,0.145,0.103,0.217,0.153c0.155,0.111,0.311,0.223,0.467,0.334
		c0.286,0.205,0.574,0.411,0.862,0.613c0.087,0.062,0.237,0.062,0.324,0c0.126-0.09,0.251-0.178,0.376-0.268
		c0.396-0.281,0.792-0.562,1.188-0.843c0.01,0.005,0.01,0.008,0.018,0.01c0.229,0.165,0.457,0.325,0.686,0.487
		c0.148,0.106,0.299,0.212,0.447,0.318c0.139,0.1,0.277,0.199,0.416,0.295c0.087,0.062,0.236,0.062,0.324,0
		c0.052-0.036,0.107-0.07,0.16-0.104v-1.041c0-0.075,0-0.161,0-0.24c0-0.145,0-0.288,0-0.44c0-1.601,0-3.548,0-4.361
		c0-0.539,0-1.076,0-1.614C13.484,5.424,13.475,5.404,13.473,5.377z M4.952,5.052c0.122-0.167,0.303-0.276,0.536-0.289
		c0.23,0.013,0.414,0.122,0.535,0.289C5.666,5.052,5.309,5.052,4.952,5.052z M12.691,7.063c0,0.868,0,3.045,0,4.701
		c0,0.005-0.001,0.011-0.001,0.016c-0.369-0.263-0.738-0.523-1.108-0.788c-0.084-0.06-0.236-0.06-0.321,0
		c-0.521,0.371-1.042,0.743-1.565,1.112c-0.006-0.002-0.008-0.005-0.017-0.01c-0.228-0.163-0.456-0.325-0.685-0.487
		c-0.287-0.206-0.574-0.406-0.863-0.615c-0.086-0.06-0.234-0.06-0.322,0c-0.147,0.105-0.294,0.21-0.441,0.314
		c-0.133,0.095-0.266,0.19-0.397,0.284c0-0.379,0-0.777,0-1.181c0-1.894,0-3.936,0-4.961c-0.013-0.256-0.083-0.482-0.19-0.687
		c1.233,0,2.469,0,3.704,0c0.507,0,1.016,0,1.523,0c0.058,0.002,0.108,0.023,0.161,0.038c0.274,0.073,0.465,0.284,0.508,0.576
		c0.004,0.026,0.012,0.047,0.016,0.072C12.691,5.987,12.691,6.524,12.691,7.063z"
      />
      <g>
        <path
          fill="#4360AC"
          d="M10.25,12.983c-0.342-0.461-0.886-0.764-1.503-0.764c-0.185,0-0.358,0.035-0.526,0.084
			c-0.779,0.229-1.352,0.942-1.352,1.795c0,1.034,0.842,1.877,1.878,1.877c1.035,0,1.878-0.843,1.878-1.877
			C10.625,13.68,10.482,13.297,10.25,12.983z M7.919,14.901c-0.202-0.208-0.33-0.491-0.33-0.803c0-0.639,0.52-1.159,1.158-1.159
			c0.313,0,0.595,0.128,0.804,0.33L7.919,14.901z"
        />
        <path
          fill="#4360AC"
          d="M7.368,11.312l0.002-0.002l0.003-0.002l-0.001-0.003c-0.048-0.312-0.16-0.592-0.336-0.829
			C7.025,10.462,7.013,10.448,7,10.436c-0.009-0.009-0.018-0.019-0.026-0.028l-0.009-0.011v0.009
			c-0.213-0.259-0.482-0.458-0.793-0.572C6.054,9.79,5.93,9.757,5.802,9.738c-1.026-0.147-1.987,0.572-2.138,1.576
			c-0.076,0.495,0.047,0.99,0.346,1.394c0.297,0.404,0.734,0.667,1.229,0.741c0.096,0.015,0.19,0.021,0.284,0.021h0.001
			c0.491,0,0.937-0.189,1.272-0.501c0.301-0.279,0.514-0.656,0.58-1.094C7.406,11.686,7.396,11.497,7.368,11.312z M6.172,12.539
			c-0.23,0.159-0.517,0.243-0.824,0.199c-0.307-0.047-0.575-0.209-0.76-0.457c-0.184-0.249-0.259-0.555-0.213-0.859
			c0.046-0.306,0.218-0.562,0.452-0.74l1.345,1.826C6.172,12.519,6.172,12.528,6.172,12.539z"
        />
      </g>
      <g>
        <path
          fill="#563D60"
          d="M8.747,12.22c-0.185,0-0.358,0.035-0.526,0.084c0.155,0.111,0.311,0.223,0.467,0.334
			c0.286,0.205,0.574,0.411,0.862,0.613c0.087,0.062,0.237,0.062,0.324,0c0.126-0.09,0.251-0.178,0.376-0.268
			C9.908,12.522,9.364,12.22,8.747,12.22z"
        />
        <path
          fill="#563D60"
          d="M6.97,11.591c0-0.379,0-0.777,0-1.181c-0.214-0.262-0.485-0.462-0.798-0.577c0,0.9,0,1.803,0,2.569
			c0,0.037,0,0.068,0,0.105l0.018,0.023c-0.005,0.004-0.012,0.005-0.018,0.008c0,0.185,0,0.368,0,0.533
			c0.023,0.032,0.045,0.065,0.083,0.093c0.069,0.05,0.193,0.05,0.263,0c0.093-0.064,0.186-0.13,0.279-0.195
			c0.301-0.279,0.514-0.656,0.58-1.094c0.029-0.192,0.02-0.383-0.01-0.569C7.234,11.401,7.102,11.497,6.97,11.591z"
        />
      </g>
      <g>
        <path
          fill="#4360AC"
          d="M11.004,6.458h-0.783V5.657c0-0.275-0.224-0.5-0.5-0.5s-0.5,0.225-0.5,0.5v0.801H8.438
			c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h0.783v0.767c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V7.458h0.783
			c0.276,0,0.5-0.224,0.5-0.5S11.28,6.458,11.004,6.458z"
        />
      </g>
    </g>
  </svg>
);

export default Prescription;
