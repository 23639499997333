import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../MainLayout";
import Navbar from "../Navbar";
import ScheduleAppointment from "../ScheduleAppointment";
import PrescriptionRenewal from "../PrescriptionRenewal";
import { MyContext } from "../../context";

const Wapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1580px;
  width: 100%;
`;

const Content = () => (
  <MyContext.Consumer>
    {context => (
      <Fragment>
        <Navbar context={context} />
        <Wapper>
          <Container>
            <Switch>
              <Route
                exact
                path="/"
                render={routeProps => (
                  <MainLayout {...routeProps} context={context} />
                )}
              />
              <Route
                path="/schedule"
                render={routeProps => (
                  <ScheduleAppointment {...routeProps} context={context} />
                )}
              />
              <Route
                path="/renouvellement"
                render={routeProps => (
                  <PrescriptionRenewal {...routeProps} context={context} />
                )}
              />
            </Switch>
          </Container>
        </Wapper>
      </Fragment>
    )}
  </MyContext.Consumer>
);

export default Content;
