import React from "react";
import { Grid } from "@material-ui/core";
import success from "../../assets/Team/success.png";
import { Bold, ContentText, SchedulingWrapper } from '../../atoms';


const Success = () => (
  <SchedulingWrapper>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={8} style={{ backgroundColor: 'white', width: '100%', textAlign: 'center', padding: '50px', color: '#6f7b91 !important' }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={success}
            alt="success"
            style={{
              width: "100px"
            }}
          />
          <Bold style={{ marginTop: '50px' }}>
            Formulaire E-mail envoyé avec succès
          </Bold>
          <ContentText style={{ marginTop: '30px', fontWeight: "400!important" }}>
            Votre demande sera traitée dans les 48 heures
          </ContentText>
        </Grid>
      </Grid>
    </Grid>
  </SchedulingWrapper>
);


export default Success;
