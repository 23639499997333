import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { string } from "prop-types";

const Text = styled(Typography)`
  font-family: "WorkSans", sans-serif !important;
  color: #6f7b91 !important;
  padding: 50px 0;
  font-weight: 500 !important;
  text-align: center !important;
  max-width: 750px !important;
`;

const SectionDescription = ({ text }) => (
  <Grid container justify="center" alignItems="center">
    <Grid item xs={10}>
      <Text variant="h5"> {text}</Text>
    </Grid>
  </Grid>
);

SectionDescription.propTypes = {
  text: string.isRequired
};

export default SectionDescription;
