import React, { useState, useRef } from "react";
import PrescriptionRenewalDisclaimer from './PrescriptionRenewalDisclaimer';
import PickUpType from './PickUpType';
import Delivery from "./Delivery";
import PickUp from "./PickUp";
import Success from "./Success";
import emailjs from '@emailjs/browser';

const MainContent = ({ handleChange }) => {
  const form = useRef();
  const [step, setStep] = useState(1);
  const nextStep = () => { setStep(step + 1) }
  const sendEmail = (e) => {
    e.preventDefault();
    console.log('form.current', form.current);
    emailjs.sendForm('service_7pevlg8', 'template_xvlss7h', form.current, '334b882Gk-V7F9I5M')
      .then((result) => {
        if (result.status === 200) {
          setStep(5)
        }
      }, (error) => {
        alert(error);
      });
  };

  switch (step) {
    case 1:
      return <PrescriptionRenewalDisclaimer nextStep={nextStep} />;
    case 2:
      return <PickUpType setStep={setStep} />;
    case 3:
      return <Delivery onChange={handleChange} sendEmail={sendEmail} form={form} />;
    case 4:
      return <PickUp onChange={handleChange} sendEmail={sendEmail} form={form} />;
    case 5:
      return <Success />;
    default:
      return null;
  }
};

export default MainContent;
