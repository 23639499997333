import React from "react";

const Urgent = () => (
  <svg
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.164 18.162"
    enableBackground="new 0 0 18.164 18.162"
  >
    <g>
      <g>
        <path
          fill="#DF6161"
          d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.057,0.035,0.117,0.068,0.176,0.104
			c0.178,0.104,0.357,0.206,0.543,0.299c0.102,0.051,0.203,0.098,0.307,0.145c0.178,0.08,0.357,0.155,0.539,0.225
			c0.107,0.041,0.215,0.081,0.324,0.117c0.197,0.066,0.396,0.123,0.6,0.176c0.098,0.025,0.193,0.055,0.291,0.078
			c0.303,0.067,0.609,0.124,0.922,0.161c0.354,0.042,0.711,0.069,1.076,0.069c1.275,0,2.49-0.268,3.594-0.744
			c0.184-0.08,0.363-0.17,0.541-0.262c0.213-0.109,0.41-0.246,0.611-0.371c0.521-0.322,1.018-0.677,1.461-1.094
			c1.766-1.658,2.875-4.004,2.875-6.61C18.164,4.073,14.09,0,9.082,0z M15.236,14.158c-0.553,0.67-1.219,1.24-1.959,1.7
			c-0.174,0.108-0.35,0.212-0.533,0.307c-0.25,0.13-0.51,0.242-0.777,0.343c-0.232,0.087-0.473,0.16-0.715,0.229
			c-0.055,0.015-0.105,0.036-0.158,0.05h0.061l0,0h-0.061c-0.125,0.032-0.244,0.079-0.371,0.105
			c-0.17,0.036-0.346,0.059-0.521,0.083c-0.367,0.053-0.738,0.088-1.119,0.088c-0.299,0-0.592-0.019-0.881-0.051
			c-0.396-0.044-0.779-0.124-1.156-0.224c-0.012-0.003-0.023-0.008-0.035-0.011l0,0c-0.236-0.064-0.469-0.141-0.697-0.226
			c-0.041-0.015-0.08-0.032-0.121-0.048C6,16.429,5.811,16.348,5.627,16.259c-0.037-0.019-0.074-0.037-0.111-0.056
			c-0.191-0.098-0.381-0.201-0.564-0.313c-0.021-0.012-0.041-0.023-0.061-0.036c-0.205-0.128-0.406-0.263-0.598-0.408v0.001
			C2.357,13.991,1.1,11.685,1.1,9.081C1.1,4.68,4.68,1.1,9.082,1.1c4.4,0,7.982,3.58,7.982,7.981
			C17.064,11.008,16.377,12.778,15.236,14.158z"
        />
      </g>
      <g>
        <path
          fill="#4360AC"
          d="M7.576,7.966c-0.222,0-0.413,0.074-0.569,0.235C6.844,8.355,6.765,8.549,6.765,8.767
			c0,0.231,0.079,0.421,0.237,0.577c0.152,0.158,0.346,0.237,0.574,0.237c0.227,0,0.423-0.079,0.577-0.237
			C8.31,9.188,8.39,8.998,8.39,8.767c0-0.218-0.082-0.411-0.241-0.565C7.99,8.04,7.799,7.966,7.576,7.966z"
        />
      </g>
      <path
        fill="#DF6161"
        d="M11.379,11.089v0.525c0,0.188,0.152,0.342,0.342,0.342s0.342-0.153,0.342-0.342v-0.525H12.6
		c0.188,0,0.342-0.153,0.342-0.342c0-0.189-0.153-0.343-0.342-0.343h-0.537V9.855c0-0.188-0.152-0.342-0.342-0.342v1.038
		c0,0.29-0.223,0.521-0.502,0.528c-0.003,0-0.006,0.002-0.01,0.002h-0.401c0.012,0.001,0.022,0.007,0.034,0.007H11.379z"
      />
      <path
        fill="#4360AC"
        d="M14.287,8.403h-2.566V5.084c0-0.292-0.229-0.53-0.512-0.53H8.233V3.332c0-0.188-0.064-0.35-0.188-0.471
		C7.924,2.742,7.763,2.679,7.567,2.679c-0.191,0-0.346,0.063-0.471,0.185C6.973,2.989,6.911,3.147,6.911,3.332v1.222H4.024
		c-0.283,0-0.512,0.238-0.512,0.53v5.468c0,0.293,0.229,0.53,0.512,0.53h1.038l0.058,2.036c0.007,0.211,0.135,0.398,0.323,0.478
		c0.062,0.024,0.126,0.037,0.189,0.037c0.132,0,0.265-0.055,0.361-0.156l2.309-2.395h0.486v1.604c0,0.209,0.163,0.379,0.365,0.379
		h2.076l1.649,1.71c0.069,0.072,0.164,0.112,0.259,0.112c0.045,0,0.09-0.009,0.134-0.026c0.137-0.055,0.227-0.191,0.231-0.341
		l0.042-1.455h0.741c0.203,0,0.365-0.17,0.365-0.379V8.782C14.652,8.573,14.49,8.403,14.287,8.403z M8.789,10.021H8.092
		c-0.137,0-0.267,0.055-0.362,0.154l-1.62,1.681l-0.038-1.32c-0.008-0.288-0.233-0.515-0.511-0.515H4.536V5.617h2.375v1.215
		c0,0.191,0.062,0.354,0.184,0.478c0.118,0.126,0.277,0.188,0.473,0.188S7.924,7.436,8.045,7.31
		c0.124-0.123,0.188-0.286,0.188-0.478V5.617h2.464v2.786v0.758v0.86H9.52H8.789z M13.922,10.464v0.23v1.614H13.19
		c-0.198,0-0.36,0.162-0.367,0.367l-0.026,0.944l-0.88-0.916l-0.276-0.286c-0.069-0.07-0.162-0.109-0.259-0.109h-0.107H9.52v-1.227
		h0.526h0.762h0.401c0.004,0,0.007-0.002,0.01-0.002c0.279-0.007,0.502-0.238,0.502-0.528V9.514V9.46V9.161h0.586h1.615V10.464z"
      />
      <g>
        <rect x="6.911" y="4.554" fill="#563D60" width="1.322" height="1.063" />
      </g>
      <path
        fill="#563D60"
        d="M11.219,11.08c0.279-0.007,0.502-0.238,0.502-0.528V9.514c-0.189,0-0.342,0.154-0.342,0.342v0.549h-0.537
		c-0.188,0-0.342,0.153-0.342,0.343c0,0.177,0.137,0.317,0.308,0.335h0.401C11.213,11.082,11.216,11.08,11.219,11.08z"
      />
    </g>
  </svg>
);

export default Urgent;
