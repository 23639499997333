import React from "react";
import { DialogContent, DialogContentText, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { ContentWrapper } from "../../atoms";

const GDPText = () => (
  <DialogContent>
    <ContentWrapper className="coiso">
      <DialogContentText id="alert-dialog-description">
        <Typography component="p" variant="p" gutterBottom>
          De par la Loi, votre médecin est tenu de collecter et de garder dans
          un dossier patient, qui vous appartient, des données relatives à votre
          état de santé, de la prise en charge et la continuité des soins.
        </Typography>
        <Typography component="p" variant="p" gutterBottom>
          La Loi oblige également votre médecin à vous informer à quelles
          finalités seront traitées et conservées vos données, y compris les
          délais de conservation et vos droits les concernant.
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          Votre dossier est traité et /ou conservé pour les finalités suivantes:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Gestion administrative (rendez-vous, comptabilité etc.);" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Gestion des dossiers médicaux : rédaction d’ordonnances et mémoires d’honoraires, etc.;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Gestion et tenue du dossier de soins;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Rédaction et transmission d’ordonnances ou rapports par tout moyen sécurisé;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Correspondance avec les confrères impliqués dans la prise en charge;(…)" />
          </ListItem>
        </List>

        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          Vos données personnelles traitées auprès du cabinet comprennent
          plusieurs éléments:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="-  Votre identité, le cas échéant celui de la personne de confiance si vous en choisissezune : nom, prénom, date de naissance, adresse, numéro de téléphone;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="-  Votre numéro de sécurité sociale nécessaire aux soins et à la communication aux organismes de sécurité sociale ou d&#39;assurance maladie complémentaire ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Votre situation de famille : situation matrimoniale, nombre d&#39;enfants, nombre de grossesses ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vos données de santé : historique médical, historique des soins, diagnostics médicaux, traitements prescrits, nature des actes effectués (tout élément pertinent de nature à déterminer la santé du patient)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Les données concernant les habitudes de vie dans la mesure où elles sont utiles au diagnostic et aux soins." />
          </ListItem>
        </List>

        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          Dans l’intérêt de votre prise en charge, vos données sont conservées
          pendant un certain délai:
        </Typography>
        <Typography component="p" variant="p" gutterBottom>
          Selon la Loi, vos données sont conservées pendant 10 ans au moins à
          partir de la prise en charge (article 15(4) de la Loi du 24 juillet
          relative aux droits et obligations du patient (…)) Comme le délai pour
          agir en responsabilité médicale est de 30 ans, votre dossier peut être
          par précaution conservé par votre médecin pendant ce délai, afin de
          prévoir sa disponibilité si une faute devait lui être reprochée.
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          Le médecin a l’obligation de veiller à une sécurisation de vos données
        </Typography>
        <Typography component="p" variant="p" gutterBottom>
          Notre logiciel informatique utilise des données hébergées sur un
          serveur local. La société informatique qui s’en occupe est tenue de
          traiter votre dossier de manière confidentielle. Votre médecin fait
          appel à la société informatique « Micromed S.A. » domiciliée à
          Helmsange (Luxembourg). Si vous ne souhaitez pas que votre médecin
          fasse héberger vos données par un tiers, vous êtes en droit de vous y
          opposer en le contactant directement ou en contactant directement
          l’hébergeur de données de santé par courrier postal ou à l’adresse
          électronique info@micromed.lu.
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          Certaines personnes ou entités, en raison de leur mission ou
          intervention concernant votre santé, sont destinataires de vos données
          :
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Les professionnels de santé impliqués dans la prise en charge, membres de l&#39;équipe chargés de soins;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Le personnel administratif, pour les données nécessaires à la gestion administrative" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Les organismes d&#39;assurance maladie, pour les données d’identification et la codification des actes effectuées." />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Les organismes de recherche dans le domaine de la santé dans les conditions définies par la réglementation en vigueur" />
          </ListItem>
        </List>

        <Typography
          component="h6"
          variant="h6"
          gutterBottom
          style={{ marginTop: 30 }}
        >
          En tant que patient vous disposez de droits que vous pouvez exercer de
          différentes manières :
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Vous pouvez demander à votre médecin de consulter votre dossier seul ou en présence de l’accompagnateur de votre choix : article 16(2) alinéa 1 de la Loi du 24 juillet relative aux droits et obligations du patient (…)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous pouvez donner l’autorisation écrite à un tiers de consulter votre dossier : article 16(2) alinéa 2 de la Loi du 24 juillet relative aux droits et obligations du patient (…) ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous pouvez demander une copie de votre dossier à votre médecin qui dispose d’un délai de 15 jours ouvrables pour y faire droit : article 16(3) (4) (5) de la Loi du 24 juillet relative aux droits et obligations du patient (…) ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous pouvez consulter votre dossier de soins partagé (DSP), s’il a été ouvert, sur la plateforme E santé (article 60 quater du Code de sécurité sociale)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous avez le droit de rectifier les données inexactes de votre dossier ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- L’éventuelle rectification ne s’applique toutefois pas aux constatations médicales que le médecin est tenu de conserver au dossier médical pendant le délai légal ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- L’éventuelle rectification ne s’applique toutefois pas aux constatations médicales que le médecin est tenu de conserver au dossier médical pendant le délai légal ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous avez le droit de compléter les données incomplètes ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous pouvez dans certaines circonstances, vous opposer à l’accès à vos données par un autre professionnel de santé, si les dispositions légales le prévoient ;" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Vous pouvez vous opposer au traitement de vos données si vous estimez que le traitement est illicite." />
          </ListItem>
        </List>
        <Typography
          component="p"
          variant="subtitle2"
          style={{ textAlign: "center", marginTop: 50 }}
        >
          Pour toute information complémentaire, vous pouvez saisir la <br />
          Commission Nationale de Protection des Données (CNPD), <br />
          L-4361 Esch-sur-Alzette, 1, avenue du Rock’n’roll ; <br />
          Tél. : (+352) 26 10 60 1 ou site www.cnpd.lu
        </Typography>
      </DialogContentText>
    </ContentWrapper>
  </DialogContent>
);

export default GDPText;
