import React from "react";

const MedicalCertificates = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.163 18.162"
    enableBackground="new 0 0 18.163 18.162"
  >
    <g>
      <path
        fill="none"
        d="M4.299,5.176c0.35,0,0.7,0,1.051,0C5.23,5.014,5.051,4.906,4.824,4.894C4.596,4.906,4.418,5.014,4.299,5.176z"
      />
    </g>
    <g>
      <g>
        <g>
          <path
            fill="#DF6161"
            d="M9.082,0C4.074,0,0,4.073,0,9.081c0,3.255,1.725,6.106,4.305,7.708c0.057,0.035,0.117,0.068,0.176,0.104
				c0.178,0.104,0.357,0.206,0.543,0.299c0.102,0.051,0.203,0.098,0.307,0.145c0.177,0.08,0.357,0.155,0.539,0.225
				c0.107,0.041,0.215,0.081,0.324,0.117c0.196,0.066,0.396,0.123,0.599,0.176c0.098,0.025,0.193,0.055,0.292,0.078
				c0.303,0.067,0.608,0.124,0.921,0.161c0.354,0.042,0.712,0.069,1.077,0.069c1.275,0,2.49-0.268,3.594-0.744
				c0.184-0.08,0.363-0.17,0.541-0.262c0.213-0.109,0.409-0.246,0.611-0.371c0.521-0.322,1.018-0.677,1.461-1.094
				c1.765-1.658,2.874-4.004,2.874-6.61C18.163,4.073,14.09,0,9.082,0z M15.235,14.158c-0.553,0.67-1.218,1.24-1.958,1.7
				c-0.174,0.108-0.351,0.212-0.533,0.307c-0.25,0.13-0.511,0.242-0.777,0.343c-0.233,0.087-0.474,0.16-0.716,0.229
				c-0.054,0.015-0.104,0.036-0.158,0.05h0.061l0,0h-0.061c-0.124,0.032-0.243,0.079-0.37,0.105
				c-0.171,0.036-0.347,0.059-0.521,0.083c-0.367,0.053-0.738,0.088-1.119,0.088c-0.299,0-0.592-0.019-0.881-0.051
				c-0.396-0.044-0.78-0.124-1.156-0.224c-0.012-0.003-0.023-0.008-0.035-0.011l0,0c-0.237-0.064-0.47-0.141-0.697-0.226
				c-0.041-0.015-0.08-0.032-0.121-0.048C6,16.429,5.811,16.348,5.627,16.259c-0.037-0.019-0.074-0.037-0.111-0.056
				c-0.192-0.098-0.382-0.201-0.565-0.313c-0.021-0.012-0.04-0.023-0.06-0.036c-0.205-0.128-0.406-0.263-0.599-0.408v0.001
				C2.357,13.991,1.1,11.685,1.1,9.081C1.1,4.68,4.68,1.1,9.082,1.1c4.4,0,7.981,3.58,7.981,7.981
				C17.063,11.008,16.377,12.778,15.235,14.158z"
          />
        </g>
      </g>
      <g>
        <path
          fill="none"
          d="M8.328,12.986c-0.029,0.102-0.067,0.193-0.115,0.28c1.709,0,3.418,0,5.125,0
			c0.229-0.011,0.407-0.118,0.526-0.28c-0.528,0-1.058,0-1.587,0C10.961,12.986,9.645,12.986,8.328,12.986z"
        />
      </g>
      <g>
        <path
          fill="#4360AC"
          d="M10.921,4.112c-0.091-0.452-0.465-0.767-0.911-0.767c-0.445,0-0.819,0.315-0.91,0.767L9.098,4.118h1.824
			L10.921,4.112z"
        />
        <path
          fill="#4360AC"
          d="M12.669,5.855l-0.006-0.001v1.789l0.006-0.002c0.422-0.113,0.706-0.474,0.706-0.897
			C13.375,6.326,13.091,5.969,12.669,5.855z"
        />
        <path
          fill="#4360AC"
          d="M10.957,4.889H9.063v0.907H7.593c-0.522,0-0.947,0.425-0.947,0.947c0,0.521,0.425,0.946,0.947,0.946h1.471
			v1.44c0,0.016,0.004,0.029,0.008,0.043c0.003,0.012,0.006,0.023,0.007,0.034c0,0.007,0.002,0.013,0.004,0.019
			C9.085,9.23,9.087,9.235,9.088,9.24l0,0.005h1.846l0-0.004c0.002-0.016,0.006-0.031,0.011-0.046
			c0.006-0.021,0.012-0.042,0.012-0.065v-1.44h0.935V5.796h-0.935V4.889z"
        />
        <path
          fill="#4360AC"
          d="M9.315,9.744c0.162,0.189,0.387,0.304,0.634,0.321h0.119h0c0.253-0.017,0.483-0.131,0.649-0.321
			l0.008-0.01H9.308L9.315,9.744z"
        />
      </g>
      <g>
        <path
          fill="#DF6161"
          d="M14.602,12.266c-0.056-0.035-0.119-0.058-0.201-0.058c-0.577,0-1.154,0-1.732,0c0-1.524,0-3.048,0-4.572
			c0-0.162,0-0.324,0-0.487c0-0.43,0-0.859,0-1.288c0-0.098,0-0.196,0-0.294c-0.002-0.025-0.014-0.045-0.017-0.07
			c-0.067-0.725-0.571-1.242-1.278-1.347c-0.055-0.008-0.101-0.033-0.158-0.036c-0.1,0-0.199,0-0.299,0c-0.398,0-0.797,0-1.195,0
			c-0.205,0-0.411,0-0.616,0c-1.427,0-2.853,0-4.28,0C4.006,4.154,3.413,4.746,3.371,5.566C3.363,5.734,3.447,5.841,3.561,5.895
			c0.057,0.037,0.119,0.06,0.201,0.06c0.578,0,1.155,0,1.733,0c0,2.215,0,4.43,0,6.642c0.042,0.818,0.634,1.412,1.452,1.453
			c2.131,0,4.261,0,6.391,0c0.82-0.041,1.412-0.633,1.453-1.453C14.8,12.428,14.715,12.321,14.602,12.266z M4.299,5.176
			c0.119-0.162,0.297-0.27,0.525-0.282C5.051,4.906,5.23,5.014,5.35,5.176C4.999,5.176,4.648,5.176,4.299,5.176z M7.682,12.403
			c-0.009,0.016-0.02,0.031-0.026,0.049c0,0,0,0.002-0.001,0.002c-0.018,0.044-0.033,0.089-0.035,0.142
			c-0.02,0.381-0.291,0.653-0.672,0.671c-0.38-0.018-0.652-0.29-0.671-0.671c0-2.344,0-4.686,0-7.029
			C6.264,5.315,6.195,5.094,6.089,4.894c0.994,0,1.987,0,2.979,0c0.218,0,0.435,0,0.652,0c0.41,0,0.821,0,1.231,0
			c0.088,0,0.175,0,0.263,0c0.058,0.003,0.107,0.022,0.158,0.036c0.27,0.072,0.457,0.28,0.498,0.566
			c0.003,0.024,0.014,0.045,0.016,0.07c0,0.078,0,0.156,0,0.234c0,0.449,0,0.898,0,1.348c0,0.179,0,0.357,0,0.536
			c0,1.508,0,3.015,0,4.523c-1.293,0-2.585,0-3.877,0C7.844,12.208,7.74,12.292,7.682,12.403z M13.338,13.267
			c-1.707,0-3.416,0-5.125,0c0.048-0.087,0.086-0.179,0.115-0.28c1.316,0,2.633,0,3.949,0c0.529,0,1.059,0,1.587,0
			C13.745,13.148,13.566,13.256,13.338,13.267z"
        />
      </g>
      <g>
        <path
          fill="#563D60"
          d="M10.921,4.112L10.92,4.108H9.101L9.1,4.112C9.096,4.133,9.09,4.153,9.084,4.173
			c-0.01,0.038-0.021,0.078-0.021,0.121v0.604h1.894V4.294c0-0.043-0.011-0.083-0.021-0.121C10.93,4.153,10.925,4.133,10.921,4.112z
			"
        />
        <path
          fill="#563D60"
          d="M12.669,5.855c-0.019-0.005-0.037-0.011-0.055-0.017c-0.038-0.013-0.078-0.025-0.12-0.029
			c-0.01-0.001-0.019-0.004-0.029-0.006c-0.012-0.004-0.024-0.007-0.038-0.007h-0.546v1.894h0.546c0.071,0,0.137-0.019,0.201-0.037
			c0.014-0.004,0.027-0.008,0.041-0.012l0.003-0.001V5.856L12.669,5.855z"
        />
      </g>
      <g>
        <path
          fill="#DF6161"
          d="M10.547,10.06c-0.16,0-0.319,0-0.479,0c-0.04,0-0.079,0-0.119,0c-0.621,0-1.242,0-1.862,0
			c-0.322,0-0.322,0.5,0,0.5c0.819,0,1.64,0,2.46,0C10.869,10.56,10.869,10.06,10.547,10.06z"
        />
        <path
          fill="#DF6161"
          d="M11.182,9.428c-0.023-0.099-0.088-0.188-0.219-0.188c-0.011,0-0.022,0-0.033,0c-0.377,0-0.753,0-1.129,0
			c-0.236,0-0.472,0-0.708,0c-0.474,0-0.948,0-1.422,0c-0.322,0-0.322,0.5,0,0.5c0.55,0,1.099,0,1.648,0c0.396,0,0.794,0,1.19,0
			c0.025,0,0.05,0,0.074,0c0.043,0,0.087,0,0.13,0c0.083,0,0.165,0,0.249,0C11.152,9.74,11.217,9.57,11.182,9.428z"
        />
      </g>
      <path
        fill="#563D60"
        d="M9.087,9.235l0.001,0.006C9.11,9.422,9.188,9.596,9.319,9.745h1.397l0.001-0.002
		c0.118-0.137,0.193-0.311,0.217-0.502l0.001-0.006H9.087z"
      />
    </g>
  </svg>
);

export default MedicalCertificates;
