import React from "react";
import { Button, CardActions, CardActionArea, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MyContext as Language } from "../../context";
import { getContent } from "../../utils";
import { TeamCardImageSmall, TeamDividerBlue, TeamTitle, TeamSubtitle, TeamMainText } from '../../atoms';

const TeamCardDetails = ({ title, subtitle, image, languages, diplomas }) => (
  <Language.Consumer>
    {({ state }) => {
      const { language } = state;
      return (
        <CardActionArea
          style={{
            color: "#224e9d",
            textAlign: "center",
            height: "700px",
            padding: 0
          }}
        >
          <Grid container justify="center" alignContent="flex-start">
            <Grid item>
              <TeamCardImageSmall
                image={image}
                style={{
                  backgroundPosition: "top",
                  borderRadius: "100%"
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              height: "50%",
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
              margin: 0,
              display: "block",
              overflow: "hidden scroll"
            }}
          >
            <CardActions>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="space-between"
              >
                <Grid item xs={12}>
                  <TeamTitle variant="h5">{title}</TeamTitle>
                </Grid>
                <TeamDividerBlue />
                <Grid item xs={12}>
                  <TeamSubtitle variant="body2">{subtitle}</TeamSubtitle>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <TeamMainText variant="subtitle2" type="title">
                    {getContent("team", "languages", language)}
                  </TeamMainText>
                  <TeamMainText variant="subtitle2">{languages}</TeamMainText>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <TeamMainText variant="subtitle2" type="title">
                    {getContent("team", "diplomas", language)}
                  </TeamMainText>
                  {diplomas.map((diploma, i) => {
                    return (
                      <span key={`diploma-{i}`}>
                        <TeamMainText variant="subtitle2">{diploma}</TeamMainText>
                        <br />
                      </span>
                    )
                  })}
                </Grid>
              </Grid>
            </CardActions>
          </div>
          <div>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#224e9d",
                    color: "white",
                    zIndex: 100
                  }}
                >
                  <Link
                    to="/schedule"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {getContent("team", "scheduleAppointment", language)}
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </div>
        </CardActionArea>
      );
    }}
  </Language.Consumer>
);


export default TeamCardDetails;
