import React from "react";

const FamilyPlanning = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="166px"
    height="166px"
    viewBox="0 0 18.163 18.163"
    enableBackground="new 0 0 18.163 18.163"
  >
    <g>
      <path
        fill="#DF6161"
        d="M9.081,0C4.073,0,0,4.074,0,9.082c0,2.478,1.004,4.718,2.619,6.355c0.507,0.513,1.069,0.97,1.685,1.353
   c0.059,0.036,0.117,0.068,0.176,0.103c0.179,0.105,0.359,0.207,0.545,0.299c0.1,0.051,0.203,0.098,0.306,0.144
   c0.177,0.081,0.356,0.156,0.54,0.225c0.106,0.041,0.214,0.081,0.322,0.118c0.104,0.035,0.211,0.062,0.316,0.093
   c0.095,0.027,0.188,0.058,0.283,0.083c0.098,0.025,0.193,0.055,0.293,0.077c0.045,0.01,0.093,0.015,0.138,0.024
   c0.257,0.055,0.517,0.106,0.782,0.138c0.354,0.042,0.712,0.07,1.076,0.07c0.637,0,1.257-0.067,1.855-0.192
   c0.244-0.051,0.48-0.118,0.717-0.188c0.349-0.104,0.691-0.221,1.021-0.364c0.186-0.08,0.363-0.17,0.542-0.261
   c0.214-0.11,0.409-0.246,0.612-0.372c0.014-0.009,0.027-0.02,0.043-0.029c0.504-0.315,0.986-0.66,1.418-1.065
   c0.028-0.027,0.054-0.058,0.082-0.085c1.714-1.653,2.791-3.962,2.791-6.525C18.163,4.074,14.089,0,9.081,0z M15.235,14.159
   c-0.264,0.319-0.556,0.612-0.865,0.884c-0.341,0.3-0.705,0.575-1.092,0.815c-0.175,0.108-0.352,0.212-0.534,0.307
   c-0.029,0.015-0.062,0.025-0.091,0.04c-0.224,0.112-0.452,0.215-0.688,0.304c-0.102,0.038-0.209,0.065-0.312,0.1
   c-0.166,0.054-0.33,0.113-0.5,0.159c-0.021,0.005-0.04,0.014-0.061,0.019c-0.053,0.014-0.104,0.028-0.155,0.044
   c-0.071,0.022-0.141,0.046-0.214,0.061c-0.172,0.036-0.348,0.059-0.522,0.083c-0.085,0.012-0.174,0.01-0.26,0.021
   c-0.284,0.033-0.567,0.067-0.86,0.067c-0.29,0-0.575-0.019-0.858-0.05c-0.007-0.001-0.015,0-0.021-0.001
   c-0.334-0.037-0.657-0.11-0.979-0.189c-0.059-0.015-0.12-0.019-0.179-0.035c-0.012-0.003-0.021-0.007-0.033-0.01v0
   c-0.171-0.046-0.336-0.104-0.502-0.162c-0.064-0.023-0.133-0.04-0.197-0.064c-0.04-0.015-0.079-0.032-0.119-0.048
   c-0.191-0.075-0.38-0.156-0.564-0.245c-0.038-0.019-0.076-0.039-0.115-0.058c-0.001,0-0.001-0.001-0.002-0.001
   c-0.188-0.096-0.375-0.197-0.556-0.307c-0.022-0.014-0.046-0.028-0.069-0.042c-0.204-0.127-0.402-0.261-0.594-0.405v0
   c-0.175-0.132-0.338-0.278-0.502-0.423c-0.41-0.364-0.78-0.771-1.108-1.211C1.695,12.489,1.1,10.858,1.1,9.082
   C1.1,4.68,4.681,1.1,9.081,1.1c4.402,0,7.982,3.581,7.982,7.982c0,1.875-0.653,3.597-1.739,4.96
   C15.294,14.08,15.267,14.122,15.235,14.159z"
      />
      <path
        fill="#4360AC"
        d="M14.229,12.64c0.671-0.281,1.144-0.944,1.144-1.716c0-1.025-0.834-1.859-1.859-1.859
   s-1.859,0.834-1.859,1.859c0,0.772,0.473,1.435,1.144,1.716c-0.671,0.281-1.144,0.943-1.144,1.715v2.254
   c-0.166,0.054-0.33,0.113-0.5,0.159c-0.021,0.005-0.04,0.014-0.061,0.019c-0.053,0.014-0.104,0.028-0.155,0.044l0.004-8.591
   c0-0.776-0.474-1.443-1.146-1.726c0.672-0.28,1.146-0.943,1.146-1.715c0-1.025-0.834-1.859-1.859-1.859S7.223,3.774,7.223,4.799
   c0,0.772,0.474,1.435,1.146,1.715C7.696,6.797,7.223,7.464,7.223,8.24v8.583c-0.059-0.015-0.12-0.019-0.179-0.035
   c-0.012-0.003-0.021-0.007-0.033-0.01v0c-0.171-0.046-0.336-0.104-0.502-0.162l0.002-5.692c0-0.772-0.474-1.435-1.146-1.715
   c0.672-0.281,1.146-0.943,1.146-1.715c0-1.025-0.834-1.859-1.859-1.859S2.792,6.468,2.792,7.493c0,0.772,0.474,1.435,1.146,1.715
   c-0.672,0.281-1.146,0.943-1.146,1.715l-0.11,2.889l-0.062,1.625c0.507,0.513,1.069,0.97,1.685,1.353
   c0.059,0.036,0.117,0.068,0.176,0.103c0.179,0.105,0.359,0.207,0.545,0.299c0.1,0.051,0.203,0.098,0.306,0.144
   c0.177,0.081,0.356,0.156,0.54,0.225c0.106,0.041,0.214,0.081,0.322,0.118c0.104,0.035,0.211,0.062,0.316,0.093
   c0.095,0.027,0.188,0.058,0.283,0.083c0.098,0.025,0.193,0.055,0.293,0.077c0.045,0.01,0.093,0.015,0.138,0.024
   c0.257,0.055,0.517,0.106,0.782,0.138c0.354,0.042,0.712,0.07,1.076,0.07c0.637,0,1.257-0.067,1.855-0.192
   c0.244-0.051,0.48-0.118,0.717-0.188c0.349-0.104,0.691-0.221,1.021-0.364c0.186-0.08,0.363-0.17,0.542-0.261
   c0.214-0.11,0.409-0.246,0.612-0.372c0.014-0.009,0.027-0.02,0.043-0.029c0.504-0.315,0.986-0.66,1.418-1.065
   c0.028-0.027,0.054-0.058,0.082-0.085v-1.252c0-0.109-0.029-0.209-0.048-0.313C15.215,13.411,14.805,12.881,14.229,12.64z
    M3.792,7.493c0-0.474,0.386-0.859,0.859-0.859S5.511,7.02,5.511,7.493S5.125,8.352,4.651,8.352S3.792,7.967,3.792,7.493z
    M5.511,16.201c-0.188-0.096-0.375-0.197-0.556-0.307c-0.022-0.014-0.046-0.028-0.069-0.042c-0.204-0.127-0.402-0.261-0.594-0.405
   v0c-0.175-0.132-0.338-0.278-0.502-0.423l0.002-4.1c0-0.474,0.386-0.859,0.859-0.859s0.859,0.385,0.859,0.859V16.201z M8.223,4.799
   c0-0.474,0.386-0.859,0.859-0.859s0.859,0.386,0.859,0.859S9.556,5.658,9.082,5.658S8.223,5.273,8.223,4.799z M9.941,16.996
   c-0.284,0.033-0.567,0.067-0.86,0.067c-0.29,0-0.575-0.019-0.858-0.05V8.24c0-0.479,0.386-0.87,0.859-0.87s0.859,0.39,0.859,0.87
   V16.996z M12.653,10.924c0-0.474,0.386-0.859,0.859-0.859s0.859,0.386,0.859,0.859c0,0.474-0.386,0.86-0.859,0.86
   S12.653,11.398,12.653,10.924z M14.37,15.043c-0.341,0.3-0.705,0.575-1.092,0.815c-0.175,0.108-0.352,0.212-0.534,0.307
   c-0.029,0.015-0.062,0.025-0.091,0.04v-1.851c0-0.474,0.386-0.859,0.859-0.859s0.859,0.385,0.859,0.859L14.37,15.043z"
      />
      <g>
        <path
          fill="#563D60"
          d="M15.324,14.042c-0.03,0.038-0.058,0.08-0.089,0.117c-0.264,0.319-0.556,0.612-0.865,0.884
     c-0.341,0.3-0.705,0.575-1.092,0.815c-0.175,0.108-0.352,0.212-0.534,0.307c-0.029,0.015-0.062,0.025-0.091,0.04
     c-0.224,0.112-0.452,0.215-0.688,0.304c-0.102,0.038-0.209,0.065-0.312,0.1c-0.166,0.054-0.33,0.113-0.5,0.159
     c-0.021,0.005-0.04,0.014-0.061,0.019c-0.053,0.014-0.104,0.028-0.155,0.044c-0.071,0.022-0.141,0.046-0.214,0.061
     c-0.172,0.036-0.348,0.059-0.522,0.083c-0.085,0.012-0.174,0.01-0.26,0.021c-0.284,0.033-0.567,0.067-0.86,0.067
     c-0.29,0-0.575-0.019-0.858-0.05c-0.007-0.001-0.015,0-0.021-0.001c-0.334-0.037-0.657-0.11-0.979-0.189
     c-0.059-0.015-0.12-0.019-0.179-0.035c-0.012-0.003-0.021-0.007-0.033-0.01v0c-0.171-0.046-0.336-0.104-0.502-0.162
     c-0.064-0.023-0.133-0.04-0.197-0.064c-0.04-0.015-0.079-0.032-0.119-0.048c-0.191-0.075-0.38-0.156-0.564-0.245
     c-0.038-0.019-0.076-0.039-0.115-0.058c-0.001,0-0.001-0.001-0.002-0.001c-0.188-0.096-0.375-0.197-0.556-0.307
     c-0.022-0.014-0.046-0.028-0.069-0.042c-0.204-0.127-0.402-0.261-0.594-0.405v0c-0.175-0.132-0.338-0.278-0.502-0.423
     c-0.41-0.364-0.78-0.771-1.108-1.211l-0.062,1.625c0.507,0.513,1.069,0.97,1.685,1.353c0.059,0.036,0.117,0.068,0.176,0.103
     c0.179,0.105,0.359,0.207,0.545,0.299c0.1,0.051,0.203,0.098,0.306,0.144c0.177,0.081,0.356,0.156,0.54,0.225
     c0.106,0.041,0.214,0.081,0.322,0.118c0.104,0.035,0.211,0.062,0.316,0.093c0.095,0.027,0.188,0.058,0.283,0.083
     c0.098,0.025,0.193,0.055,0.293,0.077c0.045,0.01,0.093,0.015,0.138,0.024c0.257,0.055,0.517,0.106,0.782,0.138
     c0.354,0.042,0.712,0.07,1.076,0.07c0.637,0,1.257-0.067,1.855-0.192c0.244-0.051,0.48-0.118,0.717-0.188
     c0.349-0.104,0.691-0.221,1.021-0.364c0.186-0.08,0.363-0.17,0.542-0.261c0.214-0.11,0.409-0.246,0.612-0.372
     c0.014-0.009,0.027-0.02,0.043-0.029c0.504-0.315,0.986-0.66,1.418-1.065c0.028-0.027,0.054-0.058,0.082-0.085v-1.252
     C15.372,14.246,15.343,14.146,15.324,14.042z"
        />
      </g>
      <path
        fill="#DF6161"
        d="M14.901,5.727h-0.783v-0.8c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v0.8h-0.783
   c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h0.783v0.767c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V6.727h0.783
   c0.276,0,0.5-0.224,0.5-0.5S15.178,5.727,14.901,5.727z"
      />
    </g>
  </svg>
);

export default FamilyPlanning;
